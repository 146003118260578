import gql from "graphql-tag";
import get from "lodash/get";

export const adminGetUserQuery = gql`
  query AdminGetUser($username: String!) {
    adminGetUser(user: { username: $username }) {
      id: username
      email
      is_enabled
      is_driver
      is_contractor
      is_admin
      name
      phone_number
      status
      sub
      username
      contractor_id
    }
  }
`;

export const adminGetUserProps = (data = { adminGetUser: {} }) => {
  const userData = get(data, "adminGetUser", {});

  const { username, name, email, status } = userData;

  return {
    email,
    isEnabled: userData.is_enabled,
    isDriver: userData.is_driver,
    isContractor: userData.is_contractor,
    isAdmin: userData.is_admin,
    name,
    phone: userData.phone_number,
    contractorId: userData.contractor_id,
    status,
    userId: userData.sub,
    username,
  };
};
