import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { navigate } from "@reach/router";
import { TextField } from "formik-mui";

import Button from "../input/Button";
import Modal from "../layout/Modal";

import styles from "./ReportForm.module.scss";
import Loading from "../layout/Loading";
import SelectTags from "./SelectTags";

const validationSchema = object().shape({
  reportName: string().required("Pakollinen kenttä"),
  reportId: string().required("Pakollinen kenttä"),
  contentId: string().required("Pakollinen kenttä")
});

class ReportForm extends React.Component {
  constructor(props) {
    super(props);
    const { report } = this.props;

    this.state = {
      isSubmitting: false,
      reportActive: true,
      selectedDrivers: report.contractorIds,
      selectedExtraReportIds: report.extraReportIds ?? ['NO_VALUE']
    };
  }
  
  onSubmitReportInfo = reportInfo => {
    const { selectedDrivers, selectedExtraReportIds } = this.state;
    reportInfo.contractorIds = selectedDrivers;
    reportInfo.extraReportIds = selectedExtraReportIds?.filter(id => id !== 'NO_VALUE') || [];
    if (reportInfo.extraReportIds.length == 0) {
      reportInfo.extraReportIds = ['NO_VALUE'];
    }

    this.props.onSubmit(reportInfo);
  }

  handleSelectedDrivers = value => {
    this.setState({selectedDrivers: typeof value === 'string' ? value.split(',') : value})
  }

  handleSelectedExtraReportIds = value => {
    if (value) {
      this.setState({selectedExtraReportIds: typeof value === 'string' ? value.split(',') : value})
    } else {
      this.setState({selectedExtraReportIds: ['NO_VALUE']});
    }
  }

  render() {
    const { isSubmitting } = this.state;
    const initialValues = { ...this.props.report };
    const { isAdmin, report, allContractors } = this.props;

    const preselectedContractors = allContractors.filter(contractorId => report.contractorIds.includes(contractorId)) || []
    const preselectedExtraReportIds = report.extraReportIds?.filter(id => id !== 'NO_VALUE') || [];

    return (
      <Modal target="/reports">
        {this.props.loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmitReportInfo}
          >
            {({ isValid }) => (
              <Form className={styles.root}>
                <header>Raportin tiedot</header>

                <label htmlFor="reportName" className={styles.label}>
                  Nimi
                </label>
                <Field
                  id="reportName"
                  name="reportName"
                  disabled={isSubmitting}
                  autoFocus
                  className={styles.field}
                  component={TextField}
                />
                <label htmlFor="reportId" className={styles.label}>
                  Raportin ID
                </label>
                <Field
                  id="reportId"
                  name="reportId"
                  disabled={isSubmitting}
                  className={styles.field}
                  component={TextField}
                />
                <label htmlFor="contentId" className={styles.label}>
                  Sisällön ID
                </label>
                <Field
                  id="contentId"
                  name="contentId"
                  disabled={isSubmitting}
                  className={styles.field}
                  component={TextField}
                />

                {isAdmin && (
                  <>
                    <label htmlFor="contractorIds" className={styles.label}>
                      Liikennöitsijät
                    </label>
                    <SelectTags
                        id="contractorIds"
                        name="contractorIds"
                        defaultValue={preselectedContractors}
                        options={allContractors || []}
                        disabled={isSubmitting}
                        onChange={this.handleSelectedDrivers} />
                  </>
                )}

                <label htmlFor="extraReportIds" className={styles.extraLabel}>
                  Raportin lisä-ID
                </label>
                <SelectTags
                  id="extraReportIds"
                  name="extraReportIds"
                  defaultValue={preselectedExtraReportIds}
                  options={[]}
                  disabled={isSubmitting}
                  onChange={this.handleSelectedExtraReportIds}
                  freeSolo
                  autoSelect />

                <Button type="submit" disabled={!isValid || isSubmitting} className={styles.buttonMargin}>
                  Tallenna
                </Button>
                <Button cancel="cancel" onClick={() => navigate("/reports")}>
                  Peruuta
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    );
  }
}

ReportForm.propTypes = {
  report: PropTypes.shape({
    reportName: PropTypes.string.isRequired,
    reportId: PropTypes.string.isRequired,
    contentId: PropTypes.string.isRequired,
    contractorIds: PropTypes.arrayOf(PropTypes.string),
    extraReportIds: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  allContractors: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ReportForm;
