import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { navigate } from "@reach/router";
import sortBy from "lodash/sortBy";

import * as Sentry from '@sentry/browser';

import { getReportsQuery, getReportsProps } from "../../api/graphql/getReports";
import { adminGetReportsProps, adminGetReportsQuery } from "../../api/graphql/adminGetReports";

import CurrentUser from "../data/CurrentUser";
import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import AddNew from "../../components/input/AddNew";
import ReportList from "../../components/reports/ReportList";
import ReportListRow from "../../components/reports/ReportListRow";

function handleClick() {
  navigate("/reports/add");
}

const Reports = ({ children, refreshStyles }) => {
  return (
    <React.Fragment>
      <CurrentUser>
        {currentUser => {
          const { isAdmin } = currentUser;
          const reportsQuery = isAdmin ? adminGetReportsQuery : getReportsQuery;
          const reportsPropsResolver = isAdmin ? adminGetReportsProps : getReportsProps;

          return (
            <Query query={reportsQuery} fetchPolicy="network-only">
              {({ loading, error, data }) => {
                if (loading) return <Loading />;
                if (error) return (<Error error={error} />);

                const result = reportsPropsResolver(data);

                return (
                  <React.Fragment>
                    {isAdmin && (
                      <AddNew onClick={handleClick} refreshStyles={refreshStyles}>
                        Lisää uusi raportti
                      </AddNew>
                    )}
                    <ReportList isAdmin={isAdmin}>
                      {sortBy(result, [report => report.reportName?.toLowerCase() ?? '']).map(
                        (report, i) => {
                          // Return updated row if exists, else hide the row
                          return (
                            <ReportListRow
                              key={report.id}
                              loading={loading}
                              report={report}
                              isAdmin={isAdmin}
                              isInvalid={!(report.reportName && report.reportId && report.contentId)}
                              index={i}
                            />
                          );
                        }
                      )}
                    </ReportList>
                  </React.Fragment>
                );
              }}
            </Query>
          );
        }}
      </CurrentUser>
      {children}
    </React.Fragment>
  );
};

Reports.propTypes = {
  children: PropTypes.node
};

Reports.defaultPropTypes = {
  children: null
};

export default Reports;
