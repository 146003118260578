import PropTypes from 'prop-types';
import React from 'react';

import styles from './OrderTransactionsSummary.module.scss';

const OrderTransactionsSummary = ({ numRows }) => (
  <div className={styles.root}>
    <h2>
      Tilauksella {`${numRows} ${numRows > 1 ? ' tilausriviä' : ' tilausrivi'}`}
    </h2>
  </div>
);

OrderTransactionsSummary.propTypes = {
  numRows: PropTypes.number.isRequired
};

export default OrderTransactionsSummary;
