import 'react-responsive-modal/styles.css';

import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';

import { logEvent } from '../../../api/graphql/logEvent';
import { updateWaybillDataMutation } from '../../../api/graphql/updateWaybillData';
import { getImages } from '../../../utils/db';
import fetchTransactionInputs from '../../../utils/fetchTransactionInputs';
import Button from '../../input/Button';
import Panel from '../../layout/Panel';
import { isWaybillSigned } from '../utils/waybills';
import { WaybillDataShape } from './shapes';
import WaybillExceptions from './WaybillExceptions';
import WaybillOrderInformation from './WaybillOrderInformation';
import WaybillOrderTransactionsSummary from './WaybillOrderTransactionsSummary';
import WaybillSignature from './WaybillSignature';
import styles from './WaybillSignatureModal.module.scss';

const logSavedImages = async (client, waybillImages, waybillNumber, customerNum) => {
  if (waybillImages) {
    const imageData = waybillImages.map((image, index) => `image${index + 1}: ${image.slice(-100)}`).join('\n');
    try {
      await client.mutate({
        mutation: logEvent,
        variables: {
          message: `Image(s) was saved. WaybillNumber: ${waybillNumber}, customerNumber: ${customerNum}, image data(s):\n${imageData}`
        },
      });
    } catch (error) {
      console.error('Error logging image', JSON.stringify(error, null, 2));
    }
  }
};

const updateWaybillData = async (client, waybillSignatureInput) => {
  try {
    const mutationResponse = await client.mutate({
      mutation: updateWaybillDataMutation,
      variables: { waybillSignature: waybillSignatureInput }
    });
    return mutationResponse.data.updateWaybillData;
  } catch (error) {
    console.log('Mutation error', JSON.stringify(error, null, 2));
  }
};

const WaybillSignatureModal = ({
  apolloClient,
  createOrderData,
  destinationId,
  getExceptions,
  getWaybillPdf,
  getWaybillSignature,
  signed,
  onSignaturesChange,
  refreshRoutes,
  type,
  waybillData,
  waybillNumber,
  actualPickupStartTime,
  actualPickupEndTime,
  actualUnloadStartTime,
  actualUnloadEndTime,
}) => {
  const [open, setOpen] = useState(false);
  const [showSignatureSuccess, setShowSignatureSuccess] = useState(false);
  const [showSignatureFail, setShowSignatureFail] = useState(false);

  const [driverNameClarification, setDriverNameClarification] = useState('');
  const [driverSignatureDataURL, setDriverSignatureDataURL] = useState(undefined);
  const [driverTimestamp, setDriverTimestamp] = useState(undefined);
  const [driverNameClarificationStyle, setDriverNameClarificationStyle] = useState({});
  const [driverNameClarificationError, setDriverNameClarificationError] = useState('');

  const [senderNameClarification, setSenderNameClarification] = useState('');
  const [senderSignatureDataURL, setSenderSignatureDataURL] = useState(undefined);
  const [senderTimestamp, setSenderTimestamp] = useState(undefined);
  const [senderSignatureNotAvailable, setSenderSignatureNotAvailable] = useState(false);
  const [senderNameClarificationStyle, setSenderNameClarificationStyle] = useState({});
  const [senderNameClarificationError, setSenderNameClarificationError] = useState('');

  const [receiverNameClarification, setReceiverNameClarification] = useState('');
  const [receiverSignatureDataURL, setReceiverSignatureDataURL] = useState(undefined);
  const [receiverTimestamp, setReceiverTimestamp] = useState(undefined);
  const [receiverSignatureNotAvailable, setReceiverSignatureNotAvailable] = useState(false);
  const [receiverNameClarificationStyle, setReceiverNameClarificationStyle] = useState({});
  const [receiverNameClarificationError, setReceiverNameClarificationError] = useState('');

  const [exceptions, setExceptions] = useState([]);

  const [updateLoadSignaturesOnUnload, setUpdateLoadSignaturesOnUnload] = useState(false);

  useEffect(async () => {
    // get signatures, if they exist
    let waybillNum = waybillNumber;
    if (!waybillNumber) {
      const transactionLoadInputs = fetchTransactionInputs();
      const transactionId = waybillData.transactions[0].pickupTransactionId;
      waybillNum = transactionLoadInputs[transactionId]?.waybill ?? waybillData.transactions[0].waybillNum;
    }

    if (!waybillNum) {
      // log is only relevant after we actually tried to open signature modal
      // console.log('WaybillSignatureModal need waybill number');
      return;
    }

    const wayBillSignatures = await getWaybillSignature(waybillData.routeId, waybillData.orderId, waybillNum);
    if (wayBillSignatures?.combinationObject) {
      setDriverNameClarification(wayBillSignatures.driverNameClarification ?? '');
      setDriverSignatureDataURL(wayBillSignatures.driverSignature);
      setDriverTimestamp(wayBillSignatures.driverTimestamp);
      setSenderNameClarification(wayBillSignatures.senderNameClarification ?? '');
      setSenderSignatureDataURL(wayBillSignatures.senderSignature);
      setSenderTimestamp(wayBillSignatures.senderTimestamp);
      setSenderSignatureNotAvailable(!!wayBillSignatures.senderSignatureNotAvailable);
      setUpdateLoadSignaturesOnUnload(true);
    }
    wayBillSignatures && onSignaturesChange(wayBillSignatures, type, waybillData.transactions);
  }, [actualPickupStartTime, actualPickupEndTime, actualUnloadStartTime, actualUnloadEndTime]);
  // reload signatures if timestamps change

  useEffect(async () => {
    if (exceptions.length === 0) {
      const exceptions = await getExceptions(waybillData.routeId, waybillData.orderId);
      setExceptions(exceptions);
    }
  }, []);

  /**
   * Checks whether all required fields were filled, updates styles based on result and returns the result
   */
  const validateSignatureData = (isOngoingLoad, isOngoingUnload, signatureData) => {
    const {
      driverNameClarification,
      driverSignature,
      driverTimestamp,
      senderNameClarification,
      senderSignature,
      senderTimestamp,
      receiverNameClarification,
      receiverSignature,
      receiverTimestamp,
      senderSignatureNotAvailable,
      receiverSignatureNotAvailable,
    } = signatureData;

    const invalidValues = [];

    if (isOngoingLoad) {
      !driverNameClarification && invalidValues.push('driverNameClarification');
      !driverSignature && invalidValues.push('driverSignature');
      !driverTimestamp && invalidValues.push('driverTimestamp');
    }

    if (isOngoingLoad && !senderSignatureNotAvailable) {
      !senderNameClarification && invalidValues.push('senderNameClarification');
      !senderSignature && invalidValues.push('senderSignature');
      !senderTimestamp && invalidValues.push('senderTimestamp');
    }

    if (isOngoingUnload && !receiverSignatureNotAvailable) {
      !receiverNameClarification && invalidValues.push('receiverNameClarification');
      !receiverSignature && invalidValues.push('receiverSignature');
      !receiverTimestamp && invalidValues.push('receiverTimestamp');
    }

    if (invalidValues.length > 0) {
      // we got some invalid values, so set some error styles and keep the modal open
      setDriverNameClarificationStyle({ border: '2px solid #f26161' });
      setSenderNameClarificationStyle({ border: '2px solid #f26161' });
      setReceiverNameClarificationStyle({ border: '2px solid #f26161' });

      invalidValues.includes('driverNameClarification') && setDriverNameClarificationError('Nimenselvennys puuttuu');
      invalidValues.includes('senderNameClarification') && setSenderNameClarificationError('Nimenselvennys puuttuu');
      invalidValues.includes('receiverNameClarification') && setReceiverNameClarificationError('Nimenselvennys puuttuu');

      return false;
    } else {
      // we got all the required values, so we clear up error styles and close the modal
      setDriverNameClarificationStyle({ border: '2px solid #f26161' });
      setSenderNameClarificationStyle({ border: '2px solid #f26161' });
      setReceiverNameClarificationStyle({ border: '2px solid #f26161' });
      setDriverNameClarificationError('');
      setSenderNameClarificationError('');
      setReceiverNameClarificationError('');
      setOpen(false);

      return true;
    }
  };

  const getSignatureDataFromState = (isOngoingLoad, isOngoingUnload) => {
    return {
      driverNameClarification: updateLoadSignaturesOnUnload || isOngoingLoad ? driverNameClarification : undefined,
      driverSignature: updateLoadSignaturesOnUnload || isOngoingLoad ? driverSignatureDataURL : undefined,
      driverTimestamp: updateLoadSignaturesOnUnload || isOngoingLoad ? driverTimestamp : undefined,
      senderNameClarification: updateLoadSignaturesOnUnload || isOngoingLoad && !senderSignatureNotAvailable ? senderNameClarification : undefined,
      senderSignature: updateLoadSignaturesOnUnload || isOngoingLoad && !senderSignatureNotAvailable ? senderSignatureDataURL : undefined,
      senderTimestamp: updateLoadSignaturesOnUnload || isOngoingLoad && !senderSignatureNotAvailable ? senderTimestamp : undefined,
      senderSignatureNotAvailable: updateLoadSignaturesOnUnload || isOngoingLoad && senderSignatureNotAvailable !== undefined ? senderSignatureNotAvailable : undefined,
      receiverNameClarification: isOngoingUnload && !receiverSignatureNotAvailable ? receiverNameClarification : undefined,
      receiverSignature: isOngoingUnload && !receiverSignatureNotAvailable ? receiverSignatureDataURL : undefined,
      receiverTimestamp: isOngoingUnload && receiverTimestamp ? receiverTimestamp : undefined,
      receiverSignatureNotAvailable: isOngoingUnload && receiverSignatureNotAvailable !== undefined ? receiverSignatureNotAvailable : undefined,
    };
  };

  const createWaybillSignatureInput = (routeId, waybillNumber, customerNum, orderId, signatureData) => {
    return {
      routeId: routeId, // key
      waybillNumber: waybillNumber, // part of composite key
      customerNumber: customerNum,
      orderId: orderId, // part of composite key
      ...signatureData,
      actualPickupStartTime,
      actualPickupEndTime,
      actualUnloadStartTime,
      actualUnloadEndTime,
    };
  };

  /**
   * - validate signature data
   * - save signature images
   * - generate pdf
   */
  const onSave = async () => {

    const signatureData = getSignatureDataFromState(waybillData.isOngoingLoad, waybillData.isOngoingUnload);
    const isValid = validateSignatureData(waybillData.isOngoingLoad, waybillData.isOngoingUnload, signatureData);

    if (isValid) {
      const transactionLoadInputs = fetchTransactionInputs();
      const transactionId = type === 'load' ? waybillData.transactions[0].pickupTransactionId : waybillData.transactions[0].unloadTransactionId;
      const waybillNumber = transactionLoadInputs[transactionId]?.waybill ?? waybillData.transactions[0].waybillNum;

      const waybillSignatureInput = createWaybillSignatureInput(waybillData.routeId, waybillNumber, waybillData.customerNum, waybillData.orderId, signatureData);

      const signatures = await updateWaybillData(apolloClient, waybillSignatureInput);
      if (!signatures) {
        console.log('Unable to modify signature data');
        setShowSignatureFail(true);
        return;
      }

      signatures && onSignaturesChange(signatures, type, waybillData.transactions);

      // create the pdf, not fully signed is considered as "preview" and gets stored to the preview bucket
      const isFullySigned = isWaybillSigned(signatures, 'load');

      if (isFullySigned) {
        setShowSignatureSuccess(true);
      }

      const images = await getImages(destinationId, waybillData.orderId);
      const filteredImages = images ? images.filter(image => image !== '' && image !== null) : images;
      await logSavedImages(apolloClient, filteredImages, waybillNumber, waybillData.customerNum);

      const orderData = await createOrderData(signatures, exceptions, true);
      await getWaybillPdf(orderData);
    } else {
      console.log('Signature data failed validation');
    }
  };

  const isValidWaybillNumber = (value) => {
    if (!value || value.trim() === '') {
      return false;
    }

    const match = value.match(/([A-Za-z0-9\-_/]+)$/gi);
    if (match === null || match[0] !== value) {
      return false;
    }

    return value && value.toString().length > 0;
  };

  const isSignButtonDisabled = () => {
    if (signed) {
      return true;
    }

    const allWaybillNumbersValid = waybillData?.rows.every(row =>
      row.transactions.every(transaction => {
        const transactionLoadInputs = fetchTransactionInputs();
        const transactionId = transaction.pickupTransactionId;
        // Get the waybill number either from transactionLoadInputs or from the transaction itself
        const waybillNumber = transactionLoadInputs[transactionId]?.waybill ?? transaction.waybillNum;
        // Validate the waybill number
        return isValidWaybillNumber(waybillNumber);
      })
    );

    return !allWaybillNumbersValid;
  };

  // Get common information from first order row transaction
  const commonInfo = waybillData?.rows[0]?.transactions[0];

  return (
    <>
      <div className={styles.fields}>
        <Button onClick={() => {
          refreshRoutes();
          setOpen(true);
        }}
        disabled={isSignButtonDisabled()}
        >
          {signed ? 'Allekirjoitettu' : 'Allekirjoita'}
        </Button>

        <Modal open={open} onClose={() => setOpen(false)}>
          <WaybillOrderTransactionsSummary numRows={waybillData?.transactions.length} />
          <WaybillOrderInformation waybillData={waybillData} commonInfo={commonInfo} />
          <WaybillExceptions exceptions={exceptions} routeId={waybillData?.routeId} orderId={waybillData?.orderId} />

          {waybillData?.isOngoingLoad && (
            <Panel className={styles.panel}>
              <b>Kuljettajan allekirjoitus</b>
              <WaybillSignature setSignatureData={(dataURL, timestamp) => {
                setDriverSignatureDataURL(dataURL);
                setDriverTimestamp(timestamp);
              }} />

              <label htmlFor="driverNameClarification" className={styles.nameClarificationLabel}>Nimenselvennys:</label>
              <input name="driverNameClarification"
                value={driverNameClarification}
                onChange={(event) => setDriverNameClarification(event.target.value)}
                style={driverNameClarificationStyle}
              />
              {driverNameClarificationError && <span style={{ color: '#ff0000', marginLeft: 10 }}>{driverNameClarificationError}</span>}
            </Panel>
          )}

          {waybillData?.isOngoingLoad && (
            <Panel
              className={`${styles.panel} ${senderSignatureNotAvailable ? styles.disabledPanel : ''}`}>
              <b>Lähettäjän allekirjoitus</b>
              <WaybillSignature setSignatureData={(dataURL, timestamp) => {
                setSenderSignatureDataURL(dataURL);
                setSenderTimestamp(timestamp);
              }} />

              <label htmlFor="senderNameClarification" className={styles.nameClarificationLabel}>Nimenselvennys:</label>
              <input name="senderNameClarification"
                value={senderNameClarification}
                onChange={(event) => setSenderNameClarification(event.target.value)}
                style={senderNameClarificationStyle}
              />
              {senderNameClarificationError && <span style={{ color: '#ff0000', marginLeft: 10 }}>{senderNameClarificationError}</span>}

            </Panel>
          )}

          {waybillData?.isOngoingLoad && (
            <span>
              <input type="checkbox" id="senderSignatureNotAvailable" name="senderSignatureNotAvailable"
                checked={senderSignatureNotAvailable}
                onChange={(event) => setSenderSignatureNotAvailable(event.target.checked)} />
              <label htmlFor="senderSignatureNotAvailable">Allekirjoitusta ei saatavilla</label>
            </span>
          )}

          {waybillData?.isOngoingUnload && (
            <Panel
              className={`${styles.panel} ${receiverSignatureNotAvailable ? styles.disabledPanel : ''}`}>
              <b>Vastaanottajan allekirjoitus</b>
              <WaybillSignature setSignatureData={(dataURL, timestamp) => {
                setReceiverSignatureDataURL(dataURL);
                setReceiverTimestamp(timestamp);
              }} />

              <label htmlFor="receiverNameClarification" className={styles.nameClarificationLabel}>Nimenselvennys:</label>
              <input name="receiverNameClarification"
                value={receiverNameClarification}
                onChange={(event) => setReceiverNameClarification(event.target.value)}
                style={receiverNameClarificationStyle}
              />
              {receiverNameClarificationError && <span style={{ color: '#ff0000', marginLeft: 10 }}>{receiverNameClarificationError}</span>}

            </Panel>
          )}

          {waybillData?.isOngoingUnload && (
            <span>
              <input type="checkbox" id="receiverSignatureNotAvailable" name="receiverSignatureNotAvailable"
                checked={receiverSignatureNotAvailable}
                onChange={(event) => {
                  setReceiverSignatureNotAvailable(event.target.checked);
                  setReceiverTimestamp(Moment().format('DD.MM.YYYY HH:mm'));
                }}
              />
              <label htmlFor="receiverSignatureNotAvailable">Allekirjoitusta ei saatavilla</label>
            </span>
          )}

          <div>
            <Button onClick={() => onSave()}>
              Tallenna
            </Button>
            <Button onClick={() => setOpen(false)}>
              Sulje
            </Button>
          </div>
        </Modal>
      </div>

      <Modal open={showSignatureSuccess} onClose={() => setShowSignatureSuccess(false)}>
        <div>
          <br/>
          <span>Rahtikirja allekirjoitettu!</span>
        </div>
        <Button onClick={() => setShowSignatureSuccess(false)}>
          OK
        </Button>
      </Modal>

      <Modal open={showSignatureFail} onClose={() => setShowSignatureFail(false)}>
        <div>
          <br/>
          <span>Rahtikirjan allekirjoittaminen epäonnistui!</span>
        </div>
        <Button onClick={() => setShowSignatureFail(false)}>
          OK
        </Button>
      </Modal>

    </>
  );
};

WaybillSignatureModal.propTypes = {
  apolloClient: PropTypes.object.isRequired,
  createOrderData: PropTypes.func.isRequired,
  destinationId: PropTypes.string.isRequired,
  getExceptions: PropTypes.func.isRequired,
  getWaybillPdf: PropTypes.func.isRequired,
  getWaybillSignature: PropTypes.func.isRequired,
  signed: PropTypes.bool.isRequired,
  onSignaturesChange: PropTypes.func.isRequired,
  refreshRoutes: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['load', 'unload']).isRequired,
  waybillData: PropTypes.shape(WaybillDataShape).isRequired,
  waybillNumber: PropTypes.string,
  actualPickupStartTime: PropTypes.string,
  actualPickupEndTime: PropTypes.string,
  actualUnloadStartTime: PropTypes.string,
  actualUnloadEndTime: PropTypes.string,
};

export default WaybillSignatureModal;
