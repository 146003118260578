import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

import DestinationFooter from "./DestinationFooter";

import styles from "./DestinationWrapper.module.scss";
import { WaybillDataShape } from "../order/shapes";
import fetchTransactionInputs from "../../../utils/fetchTransactionInputs";
import { getWaybillSignatureWrapperFunction, isWaybillSigned } from "../utils/waybills";

const resolveWaybillNumberAndSignatures = async (orderView, waybillData, type, client, routeId) => {
  // we need to fetch the signatures to know whether the waybill is signed or not
  // so that we can enable/disable the waybill/exception buttons
  const transactionLoadInputs = fetchTransactionInputs();
  const transactionId = waybillData.transactions
    .map((transaction) => {
      if (orderView) {
        return type === 'load' ? transaction.pickupTransactionId : transaction.unloadTransactionId;
      }
      return transaction.transactionId;
    })
    .filter(id => id !== undefined);
  
  let signedWithSignatureData = false;
  let waybillNumber = undefined;
  if (waybillData?.transactions) {
    waybillNumber = transactionLoadInputs[transactionId[0]]?.waybill;
    const signatures = await getWaybillSignatureWrapperFunction(client, routeId, waybillData.orderId, waybillNumber);
    signedWithSignatureData = isWaybillSigned(signatures, type);
  }

  if (!signedWithSignatureData) {
    // Transactions may be from different orders but should be of same type, either load/unload.
    // Activate only if all of them are signed even if not displayed (single order view).
    const signedKey = type === 'load' ? 'signedOnLoad' : 'signedOnUnload';
    const isSigned = transactionId.every(t => transactionLoadInputs[t]?.[signedKey] ?? false);
    signedWithSignatureData = isSigned;
  }

  return { waybillNumber, signed: signedWithSignatureData };
}

const DestinationWrapper = ({
  allowActions,
  allowExceptions,
  children,
  className,
  client,
  currentVehicle,
  destination,
  headerLinkTarget,
  headerLinkText,
  id,
  isLoading,
  isOnline,
  newWaybillNumber,
  orderView,
  refreshRoutes,
  relatedOrders,
  toggleState,
  waybillData,
  actualPickupStartTime,
  actualPickupEndTime,
  actualUnloadStartTime,
  actualUnloadEndTime,
  setSignedWaybillNumber
}) => {
  const [fetching, setFetching] = useState(false);
  
  const [signed, setSigned] = useState(false);
  const [waybillNumber, setWaybillNumber] = useState(newWaybillNumber);
  const [finalSubmitOngoing, setFinalSubmitOngoing] = useState(false);

  const headerText = `${destination.type === "load" ? "Lastaus" : "Purku"} – ${destination.name}`;

  useEffect(() => {
    const fetchInitialData = async () => {
      setFetching(true);
      const result = await resolveWaybillNumberAndSignatures(orderView, waybillData, destination.type, client, destination.routeId);
      if (result.waybillNumber !== waybillNumber) {
        setWaybillNumber(result.waybillNumber);
      }
      if (result.signed !== signed) {
        setSigned(result.signed);
      }
      setFetching(false);
    };

    if (!fetching) {
      fetchInitialData();
    }
  }, [orderView, waybillData, destination.type, destination.routeId, client]);

  useEffect(() => {
    // Do not update waybill number if destination is unload, waybillNumber should not change on unload
    if (destination.type !== "unload" && newWaybillNumber !== waybillNumber) {
      setWaybillNumber(newWaybillNumber);
    }
  }, [newWaybillNumber]);

  useEffect(() => {
    if (setSignedWaybillNumber) {
      setSignedWaybillNumber(signed);
    }
  }, [signed]);


  return (
    <div
      className={`${styles.wrapper} ${styles[className]} ${destination.state === "completed"
        ? styles.completed
        : destination.state === "ongoing"
          ? destination.type === "load"
            ? styles.loading
            : styles.unloading
          : null
        }`}
    >
      <header>
        {headerLinkTarget && (
          <Link to={headerLinkTarget}>{headerLinkText}</Link>
        )}
        <span>{headerText}</span>
      </header>
      <div className={`${styles.root}`}>
        <div className={styles.scroll}>{children}</div>
      </div>

      <DestinationFooter
        allowActions={allowActions}
        allowExceptions={allowExceptions}
        appSyncClient
        ata={destination.ata}
        atd={destination.atd}
        client={client}
        currentVehicle={currentVehicle}
        destinationId={destination.id}
        eta={destination.eta}
        etd={destination.etd}
        id={id}
        isLoading={isLoading}
        isOnline={isOnline}
        onAction={() => {
          toggleState(destination);
        }}
        orderView={orderView}
        refreshRoutes={refreshRoutes}
        relatedOrders={relatedOrders}
        routeId={destination.routeId}
        state={destination.state}
        transactions
        type={destination.type}
        waybillData={waybillData}
        actualPickupStartTime={actualPickupStartTime}
        actualPickupEndTime={actualPickupEndTime}
        actualUnloadStartTime={actualUnloadStartTime}
        actualUnloadEndTime={actualUnloadEndTime}
        waybillNumber={waybillNumber}
        signed={signed}
        setSigned={setSigned}
        finalSubmitOngoing={finalSubmitOngoing}
        setFinalSubmitOngoing={setFinalSubmitOngoing}
        fetching={fetching}
      />
    </div>
  );
};

DestinationWrapper.propTypes = {
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentVehicle: PropTypes.object,
  destination: PropTypes.shape({
    type: PropTypes.oneOf(["load", "unload"]).isRequired,
    name: PropTypes.string,
    state: PropTypes.oneOf([
      "new",
      "updated",
      "cancelled",
      "completed",
      "ongoing"
    ])
  }).isRequired,
  headerLinkTarget: PropTypes.string,
  headerLinkText: PropTypes.string,
  isLoading: PropTypes.bool,
  isOnline: PropTypes.bool,
  newWaybillNumber: PropTypes.string,
  refreshRoutes: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,  isLoading: PropTypes.bool,
  waybillData: PropTypes.shape(WaybillDataShape),
  waybillImages: PropTypes.arrayOf(PropTypes.string),
};

DestinationWrapper.defaultPropTypes = {
  headerLink: "../",
  headerLinkText: "Takaisin",
  className: "",
  isLoading: false,
  isOnline: false,
  waybillImages: [],
};

export default DestinationWrapper;
