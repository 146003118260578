import React from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "./ReportList.module.scss";

class ReportList extends React.Component {
  render() {
    const { children, isAdmin } = this.props;

    return (
      <React.Fragment>
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nimi</TableCell>
                {isAdmin && (<>
                  <TableCell>Raportin ID</TableCell>
                  <TableCell>Sisällön ID</TableCell>
                </>)}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

ReportList.propTypes = {
  iaAdmin: PropTypes.bool,
  children: PropTypes.node
};

export default ReportList;
