import React from "react";
import PropTypes from "prop-types";

import Button from "../components/input/Button";
import Modal from "../components/layout/Modal";

class AppManager extends React.Component {
  constructor(props) {
    super(props);
    this.checkAppStatus = this.checkAppStatus.bind(this);
    this.state = { 
      AppVersion: props.appVersion,
      appVersionUrl: props.appVersionUrl,
      isOnline: false,
      requiresRefresh: false,
      isLocalhost: ["localhost", "127.0.0.1", ""].includes(window.location.hostname),
    };
  }
  componentDidMount() {
    this.checkAppStatus(); 
    this.interval = setInterval(
      this.checkAppStatus,
      //5 * 60 * 1000 // 5 minutes
      10 * 1000 // TEST
    );
  }

  UNSAFE_componentWillUnmount() {
    if(this.interval !== null){
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  async checkAppStatus() {
    try {
      // Only bother checking if the app is awaiting a user refresh
      if (this.state.requiresRefresh) {
        console.log('Application version is expired, please refresh.');
      } else {
        const res = await fetch(this.state.appVersionUrl, {cache: "reload"});
        const awsConfig = await res.json();
        // Set state change if the app has just come online OR it needs reloaded
        if ((this.state.isOnline !== true) ||
            (awsConfig.ApplicationVersion !== this.state.AppVersion && !this.state.requiresRefresh)) {
          this.setState({
            isOnline: true, // If no fetch error then the app is online
            requiresRefresh: (awsConfig.ApplicationVersion !== this.state.AppVersion)?true:false,
          });
        }
      }
    } catch (err) {
      console.log('Error when fetching application config, application is offline.');
      if (this.state.isOnline !== false) {
        this.setState({
          isOnline: false
        });
      }
    }
  }

  reloadApp(e) {
    window.location.reload(true);
    e.preventDefault();
  }

  render() {
    if (this.state.requiresRefresh && !this.state.isLocalhost) {
      return (
        <Modal uncloseable>
          Rahtarista on saatavilla uusi versio, ole hyvä ja päivitä sovellus
          <br />
          <br />
          <Button onClick={this.reloadApp}>Päivitä</Button>
        </Modal>
      );
    }
    return this.props.children(this.state.isOnline);
  }
}

AppManager.propTypes = {
  appVersionUrl: PropTypes.string.isRequired,
  appVersion: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

AppManager.defaultProps = {
  appVersionUrl: `${window.location.origin}/aws-config.json`,
  appVersion: "",

};

export default AppManager;
