import * as React from 'react'
import PropTypes from 'prop-types';
import { Autocomplete, Stack, TextField } from '@mui/material';

const SelectTags = ({ options, defaultValue, disabled, label, onChange, ...rest }) => {
  const [value, setValue] = React.useState(defaultValue);

  return (
    <Stack spacing={3} >
      <Autocomplete
        value={value || []}
        multiple
        options={options?.sort() || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        filterSelectedOptions
        onChange={(_, newValue) => {
          setValue(newValue);
          if (onChange) {
            onChange(newValue.map(item => item));
          }
        }}
        disabled={disabled || false}
        renderInput={(params) => (
          <TextField {...params} label={label} />
        )}
        isOptionEqualToValue={(option, value) => option === value }
        {...rest}
      />
    </Stack>
  );
}

SelectTags.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SelectTags;