import gql from "graphql-tag";

export const selectVehicleMutation = gql`
  mutation SelectVehicle($vehicleId: String!, $licenseNum: String, $contractorName: String) {
    selectVehicle(vehicleId: $vehicleId, licenseNum: $licenseNum, contractorName: $contractorName) @client {
      currentVehicle @client {
        vehicleId
        licenseNum
        contractorName
      }
    }
  }
`;
