import gql from "graphql-tag";

export const getExceptionsQuery = gql`
  query GetExceptions($routeId: ID, $orderId: ID) {
    getExceptions(routeId: $routeId, orderId: $orderId) {
      vehicleLicense
      time
      locX
      locY
      routeId
      destinationId
      place
      orderId
      transactionId
      code
      type
      status
      info
    }
  }
`;

export const getExceptionsProps = (data) => {
  return data.getExceptions?.map(item => ({
    vehicleLicense: item.vehicleLicense,
    time: item.time,
    locX: item.locX,
    locY: item.locY,
    routeId: item.routeId,
    destinationId: item.destinationId,
    place: item.place,
    orderId: item.orderId,
    transactionId: item.transactionId,
    code: item.code,
    type: item.type,
    status: item.status,
    info: item.info
  })) ?? []
};
