import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { getOrderQuery } from "../../api/graphql/getOrder";

import getUniqueProps from "../../utils/getUniqueProps";
import AWSAppSyncClient from "aws-appsync";

const PrefetchDestinationOrders = ({ destination, children, client }) => {
  /*const transactions = destinations.reduce((acc, destination) => {
    if (destination.transactions) {
        acc.push(...destination.transactions);
    }
    return acc;
  }, []);*/
  const transactions = destination.transactions;
  const orderNums = getUniqueProps(transactions, "orderNum");

  const fetchOrders = async (orderNums) => {
    return Promise.all(orderNums.sort().map(async (orderNum) => {
        try {
            await client.query({
                query: getOrderQuery,
                variables: { orderId: `${orderNum}` },
                fetchPolicy: "cache-first"
            });
        } catch (err) {
            console.error('Error prefetching order - is device online?', err);
        }
    }));
  };
  fetchOrders(orderNums);

  return <React.Fragment>{children}</React.Fragment>
};

PrefetchDestinationOrders.propTypes = {
  client: PropTypes.instanceOf(AWSAppSyncClient).isRequired,
  destination: PropTypes.shape({
      transactions: PropTypes.arrayOf(
        PropTypes.shape({
          transactionId: PropTypes.string,
          orderNum: PropTypes.string
        })
      ).isRequired,
      ata: PropTypes.string,
      atd: PropTypes.string,
      type: PropTypes.oneOf(["load", "unload"])
  }).isRequired,
  children: PropTypes.node,
};

export default withApollo(PrefetchDestinationOrders);
