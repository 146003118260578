import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { navigate } from "@reach/router";
import { TextField, Switch } from "formik-mui";
import { FormControlLabel } from "@material-ui/core";

import Button from "../input/Button";
import Modal from "../layout/Modal";

import styles from "./DriverForm.module.scss";
import Loading from "../layout/Loading";

const validationSchema = object().shape({
  name: string().required("Pakollinen kenttä"),
  username: string().required("Pakollinen kenttä"),
  email: string()
    .email()
    .required("Pakollinen kenttä"),
  phone: string().matches(/^\+\d+$/, "Anna numero muodossa +358012345678")
});

const adminValidationSchema = object().shape({
  name: string().required("Pakollinen kenttä"),
  username: string().required("Pakollinen kenttä"),
  email: string()
    .email()
    .required("Pakollinen kenttä"),
  phone: string().matches(/^\+\d+$/, "Anna numero muodossa +358012345678"),
  contractorId: string().required("Pakollinen kenttä")
});

const PasswordResetButton = ({children, onClick}) => {
  return (
  <button 
    type="button"
    className={styles.passwordReset}
    onClick={event => {
      event.stopPropagation();
      event.preventDefault();
      onClick && onClick();
    }}
  >
    <span className={styles.action}>{children}</span>
    <span className={styles.dots}></span>
  </button>
)};

class DriverForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      driverActive: true,
      isLoading: true,
      isContractor: this.props.driver?.isContractor ?? false
    };
  }

  onSubmitDriverInfo = userInfo => {
    this.props.onSubmit(userInfo);
  };

  handleOnChange = (event) => {
    if (event.target.name == "isContractor")
    {
      this.setState({ isContractor: event.target.value });
    }
  };

  render() {
    const { isSubmitting } = this.state;
    const initialValues = { ...this.props.driver };
    const { isAdmin, onResetPassword } = this.props;
 
    return (
      <Modal target="/admin/drivers">
        {this.props.loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={isAdmin ? adminValidationSchema : validationSchema}
            onSubmit={this.onSubmitDriverInfo}
          >
            {({ isValid }) => (
              <Form className={styles.root} onChange={this.handleOnChange}>
                <header>Käyttäjätiedot</header>

                <FormControlLabel
                  control={
                    <Field
                      name="isEnabled"
                      component={Switch}
                      defaultChecked={initialValues.isEnabled}
                    />
                  }
                  label="Aktiivinen"
                />
                <br />
                <br />

                <label htmlFor="username" className={styles.label}>
                  Käyttäjätunnus
                </label>
                <Field
                  id="username"
                  name="username"
                  disabled={true}
                  className={styles.field}
                  component={TextField}
                />
                <label htmlFor="password" className={styles.label}>
                  Salasana
                </label>
                <div className={styles.field}>
                  <div className={styles.textfieldLookalike}>
                    <PasswordResetButton onClick={onResetPassword}>
                      Nollaa salasana
                    </PasswordResetButton>
                  </div>
                </div>
                <label htmlFor="name" className={styles.label}>
                  Nimi
                </label>
                <Field
                  id="name"
                  name="name"
                  disabled={isSubmitting}
                  autoFocus
                  className={styles.field}
                  component={TextField}
                />
                <label htmlFor="email" className={styles.label}>
                  Sähköposti
                </label>
                <Field
                  id="email"
                  type="email"
                  name="email"
                  disabled={isSubmitting}
                  className={styles.field}
                  component={TextField}
                />
                <label htmlFor="phone" className={styles.label}>
                  Puhelin
                </label>
                <Field
                  id="phone"
                  type="tel"
                  name="phone"
                  disabled={isSubmitting}
                  className={styles.field}
                  component={TextField}
                />
                {isAdmin && (
                  <React.Fragment>
                    <br />
                    <br />
                    <br />
                    <br />
                    <FormControlLabel
                      control={
                        <Field
                          name="isDriver"
                          component={Switch}
                          defaultChecked={initialValues.isDriver}
                        />
                      }
                      label="Kuljettaja"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          name="isContractor"
                          component={Switch}
                          defaultChecked={initialValues.isContractor}
                        />
                      }
                      label="Liikennöitsijä"
                    />
                    <br />
                    <br />

                    <label htmlFor="contractorId" className={styles.label}>
                      Liikennöitsijätunnus
                    </label>
                    <Field
                      id="contractorId"
                      name="contractorId"
                      disabled={isSubmitting}
                      className={styles.field}
                      component={TextField}
                    />
                    <br />
                    <br />
                  </React.Fragment>
                )}
                <Button type="submit" disabled={!isValid || isSubmitting} className={styles.buttonMargin}>
                  Tallenna
                </Button>
                <Button
                  cancel="cancel"
                  onClick={() => {
                    navigate("/admin/drivers");
                  }}
                >
                  Peruuta
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    );
  }
}

DriverForm.propTypes = {
  driver: PropTypes.shape({
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    isDriver: PropTypes.bool,
    isContractor: PropTypes.bool,
    isAdmin: PropTypes.bool,
    email: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default DriverForm;
