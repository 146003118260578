import React from 'react';
import { withApollo } from "react-apollo";
import { updateVehicleLocationMutation } from "../../api/graphql/updateVehicleLocation";
import getTimeStamp from "../../utils/getTimeStamp";
import get from 'lodash/get';
import PropTypes from "prop-types";

class UpdateVehicleLocation extends React.Component {
  updateInterval = null;
  
  constructor(props){
    super(props);
    this.locationUpdate = this.locationUpdate.bind(this);
  }

  componentDidMount(){
    this.updateInterval = setInterval(this.locationUpdate, this.props.interval)
  }
  UNSAFE_componentWillUnmount(){
    if(this.updateInterval !== null){
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }
  locationUpdate(){
    const location = this.props.geolocation.getLocation();
    if(location !== null){
      const { client, vehicleId } = this.props;
      client.mutate({
        mutation: updateVehicleLocationMutation,
        variables: {
          id: vehicleId,
          vehicleId: vehicleId,
          timestamp: getTimeStamp(get(location, "timestamp", undefined)),
          locX: get(location, "longitude", undefined),
          locY: get(location, "latitude", undefined)
        }
      });
    }
  }
  render() {
    return null;
  }
}

UpdateVehicleLocation.propTypes = {
  vehicleId: PropTypes.string.isRequired,
  interval: PropTypes.number.isRequired,
  geolocation: PropTypes.shape({
    restart: PropTypes.func.isRequired,
    geolocated: PropTypes.bool.isRequired,
    getLocation: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
  })
};

export default withApollo(UpdateVehicleLocation);