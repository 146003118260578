const RouteExceptionTypes = {
  "TAVARA PUUTTUU": {
    "label": "Tavara puuttuu",
    "code": 27
  },
  "TAVARA EI MAHDU": {
    "label": "Tavara ei mahdu",
    "code": 41
  },
  "TAVARA EI VALMIS": {
    "label": "Tavara ei valmis",
    "code": 348
  },
  "TAVARAVAURIO": {
    "label": "Tavaravaurio",
    "code": 14
  },
  "ODOTUS: RUUHKA": {
    "label": "Odotus: ruuhka",
    "code": 194
  },
  "ODOTUS: HENKILÖSTÖVAJE": {
    "label": "Odotus: henkilöstövaje",
    "code": 191
  },
  "ODOTUS: KALUSTOVAJE": {
    "label": "Odotus: kalustovaje",
    "code": 195
  },
  "ODOTUS: MUU SYY": {
    "label": "Odotus: muu syy",
    "code": 265
  },
  "JAKO": {
    "label": "Jako",
    "code": 55
  },
  "AVISOINTI": {
    "label": "Avisointi",
    "code": 68
  },
  "TELAUS": {
    "label": "Telaus",
    "code": 136
  },
  "KELAPUKKI": {
    "label": "Kelapukki",
    "code": 54
  },
  "LAUANTAI": {
    "label": "Lauantai",
    "code": 133
  },
  "SUNNUNTAI/PYHÄPÄIVÄ": {
    "label": "Sunnuntai/pyhäpäivä",
    "code": 134
  },
  "PALAUTUS": {
    "label": "Palautus",
    "code": 82
  },
  "PURKULAITE": {
    "label": "Purkulaite",
    "code": 258
  },
  "KALUSTOVAHINKO": {
    "label": "Kalustovahinko",
    "code": 34
  },
  "MUU": {
    "label": "Muu",
    "code": 62
  },
  "SIJAINNIN PAIKANNUSONGELMA": {
    "label": "Sijainnin paikannusongelma",
    "code": 274
  },
  "SÄÄOLOSUHTEET": {
    "label": "Sääolosuhteet",
    "code": 63
  },
};

const RouteExceptionStatusTypes = {
  "loading_started": 132,
  "loading_completed": 13,
  "unloading_started": 135,
  "unloading_completed": 21,
  "driving": 31,
  "other": null
};

const RouteExceptionStatusTypeTranslations = {
  "loading_started": "Lastaus alkanut",
  "loading_completed": "Lastaus päättynyt",
  "unloading_started": "Purku alkanut",
  "unloading_completed": "Purku päättynyt",
  "driving": "Ajossa",
  "other": "Siirtymä"
};

const routeExceptionCodeToLabel = (code) => {
  code = stringToNumber(code);
  for (const key in RouteExceptionTypes) {
    if (stringToNumber(RouteExceptionTypes[key].code) === code) {
      return RouteExceptionTypes[key].label;
    }
  }
  return undefined;
}

const routeExceptionStatusCodeToLabel = (code) => {
  code = stringToNumber(code);
  for (const key in RouteExceptionStatusTypes) {
    if (RouteExceptionStatusTypes[key] === code) {
      return key;
    }
  }
  return undefined;
}

const stringToNumber = (value) => {
  if (typeof value === 'number' && isFinite(value)) {
    return value;
  }

  if (typeof value === 'string') {
    const trimmedValue = value.trim();

    // Use parseFloat to handle both integers and floats
    // Check if the parsed number is finite to exclude Infinity, -Infinity, and NaN
    const parsed = parseFloat(trimmedValue);
    if (isFinite(parsed)) {
      return parsed;
    }
  }
  return undefined;
}

module.exports = {
  RouteExceptionTypes: RouteExceptionTypes,
  RouteExceptionStatusTypes: RouteExceptionStatusTypes,
  RouteExceptionStatusTypeTranslations: RouteExceptionStatusTypeTranslations,
  routeExceptionCodeToLabel: routeExceptionCodeToLabel,
  routeExceptionStatusCodeToLabel: routeExceptionStatusCodeToLabel,
  stringToNumber: stringToNumber
};
