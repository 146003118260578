import React from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "react-apollo";
import { navigate } from "@reach/router";

import * as Sentry from '@sentry/browser';

import {
  adminGetUserQuery,
  adminGetUserProps
} from "../../api/graphql/adminGetUser";
import { getUserQuery, getUserProps } from "../../api/graphql/getUser";
import { adminUpdateUserMutation } from "../../api/graphql/adminUpdateUser";
import { updateDriverMutation } from "../../api/graphql/updateDriver";

import get from "lodash/get";
import Modal from "../../components/layout/Modal";
import CurrentUser from "../data/CurrentUser";
import Error from "../../components/layout/Error";
import DriverForm from "../../components/usermanagement/DriverForm";
import ResetDriverPasswordConfirmation from "../../components/usermanagement/ResetDriverPasswordConfirmation";
import { resetPasswordMutation } from "../../api/graphql/resetPassword";
import { adminResetPasswordMutation } from "../../api/graphql/adminResetPassword";
import { adminRemoveUserFromReportsMutation } from "../../api/graphql/adminRemoveUserFromReports";

const Driver = ({ username }) => {

  if (!username) return null;
  return (
    <CurrentUser>
      {currentUser => {
        const { isAdmin } = currentUser;
        const getUserDetailsQuery = isAdmin ? adminGetUserQuery : getUserQuery;
        const userPropsResolver = isAdmin ? adminGetUserProps : getUserProps;
        const driverMutation = isAdmin
          ? adminUpdateUserMutation
          : updateDriverMutation;
        const driverResetPasswordMutation = isAdmin ? adminResetPasswordMutation : resetPasswordMutation;

        return (
          <Query 
            query={getUserDetailsQuery} 
            variables={{ username: username }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if(error) {
                Sentry.captureEvent({
                  message: "Query error - Driver",
                  extra: {
                    'error': error,
                    'data': data,
                    'variableUsername': username
                  },
                });          
              }
              if (error) return <Error error={error} />;

              const driver = userPropsResolver(data);

              return (
                <Mutation
                  mutation={driverMutation}
                  refetchQueries={[
                    {
                      query: getUserDetailsQuery,
                      variables: { username: driver.username }
                    }
                  ]}
                  onCompleted={() => navigate("/admin")}
                >
                  {updateDriver => (
                    <Mutation
                      mutation={driverResetPasswordMutation}
                      variables={{username: driver.username}}
                      refetchQueries={[
                        {
                          query: getUserDetailsQuery,
                          variables: { username: driver.username }
                        }
                      ]}
                    >
                      {(resetPassword, {data}) => {
                        const tempPassword = (isAdmin) ? 
                          get(data, ["adminResetPassword", "initial_password"], false) :
                          get(data, ["resetPassword", "initial_password"], false);
                        return !tempPassword ? (
                            <Mutation mutation={adminRemoveUserFromReportsMutation} variables={{id: driver.userId}}>
                              {updateReports => (
                                <DriverForm
                                  loading={loading}
                                  driver={driver}
                                  isAdmin={isAdmin}
                                  onSubmit={userInfo => {
                                    updateDriver({
                                      variables: {
                                        username: userInfo.username,
                                        name: userInfo.name,
                                        email: userInfo.email,
                                        phone_number: userInfo.phone,
                                        is_enabled: userInfo.isEnabled,
                                        is_driver: userInfo.isDriver,
                                        is_contractor: userInfo.isContractor,
                                        contractor_id: userInfo.contractorId
                                      }
                                    });
                                    // Make sure reports cannot be linked to users that do not belong to contractors group.
                                    if (isAdmin && !userInfo.isContractor)
                                    {
                                      updateReports({
                                        variables: {
                                          id: driver.userId
                                        }
                                      });
                                    }
                                  }}
                                  onResetPassword={resetPassword}
                                />
                              )}
                            </Mutation>
                          ) : (
                            <Modal uncloseable={true}>
                              <ResetDriverPasswordConfirmation
                                {...driver}
                                firstname={driver.name.split(" ")[0]}
                                initialPassword={tempPassword}
                              />
                            </Modal>
                          );
                        }}
                    </Mutation>
                  )}
                </Mutation>
              );
            }}
          </Query>
        );
      }}
    </CurrentUser>
  );
};

Driver.propTypes = {
  username: PropTypes.string
};

Driver.defaultPropTypes = {
  username: null
};

export default Driver;
