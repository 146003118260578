import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';

import App from "./App";
import "./index.css";

Sentry.init({
  dsn: "",
  environment: (process.env.stage) ? process.env.stage : "develop"
});

const configPromise = fetch('/aws-config.json');

configPromise.then((res) => {
  res.json().then((awsConfig) => {
    //const env = (awsConfig.Stage)
    ReactDOM.render(<App awsConfig={awsConfig} />, document.getElementById("root"));
  });
});
