import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";

import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import ViewPowerBIReport from "../../components/reports/ViewPowerBIReport";
import CurrentUser from "../data/CurrentUser";
import { adminGetReportProps, adminGetReportQuery } from "../../api/graphql/adminGetReport";
import { getReportsProps, getReportsQuery } from "../../api/graphql/getReports";

const PowerBI = ({ id }) => {
    if (!id) return null;

    return (
        <CurrentUser>
            {currentUser => {
                const { isAdmin } = currentUser;

                return isAdmin ? (
                    <Query query={ adminGetReportQuery }
                        variables={{ id }}
                        fetchPolicy="network-only" >
                        {({ loading, error, data }) => {
                            if (loading) return <Loading />;
                            if (error) return <Error error={error} />;

                            const report = adminGetReportProps(data);
                            
                            return (
                                <ViewPowerBIReport user={currentUser} report={report} />
                            );
                        }}
                    </Query>
                ) : (
                    <Query query={ getReportsQuery }
                        fetchPolicy="network-only" >
                        {({ loading, error, data }) => {
                            if (loading) return <Loading />;
                            if (error) return <Error error={error} />;

                            const reports = getReportsProps(data);
                            const report = reports.find(x => x.id === id)
                            
                            return (
                                <ViewPowerBIReport user={currentUser} report={report} />
                            );
                        }}
                    </Query>
                )
            }}
        </CurrentUser>
    )
};

PowerBI.propTypes = {
  id: PropTypes.string
};

PowerBI.defaultPropTypes = {
  id: null
};

export default PowerBI;
