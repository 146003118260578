import React from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "react-apollo";
import { navigate } from "@reach/router";

import * as Sentry from '@sentry/browser';

import { adminGetReportQuery, adminGetReportProps } from "../../api/graphql/adminGetReport";
import { adminUpdateReportMutation } from "../../api/graphql/adminUpdateReport";

import CurrentUser from "../data/CurrentUser";
import Error from "../../components/layout/Error";
import ReportForm from "../../components/reports/ReportForm";
import Spinner from "../../components/layout/Spinner";
import { adminGetContractorsQuery } from "../../api/graphql/adminGetContractors";

const Report = ({ id }) => {

  if (!id) return null;
  return (
    <CurrentUser>
      {currentUser => {
        const { isAdmin } = currentUser;

        if (!isAdmin) {
          return null;
        }

        return isAdmin ? (
          <Query query={adminGetReportQuery}
            variables={{ id }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if(error) {
                Sentry.captureEvent({
                  message: "Query error - Report",
                  extra: {
                    'error': error,
                    'data': data,
                    'variableId': id
                  },
                });          
              }
              if (loading) return <Spinner />
              if (error) return <Error error={error} />;

              const report = adminGetReportProps(data);
              
              return (
                <Mutation
                  mutation={adminUpdateReportMutation}
                  variables={{ id }}
                  refetchQueries={[
                    {
                      query: adminGetReportQuery,
                      variables: { id }
                    }
                  ]}
                  onCompleted={() => navigate("/reports")}
                >
                  {updateReport => (
                    <Query query={adminGetContractorsQuery}>
                      {({ loading, error, data }) => {
                        if (loading) return <Spinner />;
                        if (error) return <Error error={error} />;

                        const { adminGetContractors: allContractors } = data;
                        return (
                          <ReportForm
                            isAdmin={isAdmin}
                            loading={loading}
                            report={report}
                            allContractors={allContractors}
                            onSubmit={reportInfo => {
                              updateReport({
                                variables: {
                                  id,
                                  reportName: reportInfo.reportName,
                                  reportId: reportInfo.reportId,
                                  contentId: reportInfo.contentId,
                                  contractorIds: reportInfo.contractorIds,
                                  extraReportIds: reportInfo.extraReportIds
                                }
                              });
                            }}
                          />
                        )
                      }} 
                    </Query>
                  )}
                </Mutation>
              );
            }}
          </Query>
        ) : null;
      }}
    </CurrentUser>
  );
};

Report.propTypes = {
  id: PropTypes.string
};

Report.defaultPropTypes = {
  id: null
};

export default Report;
