import gql from "graphql-tag";
import get from "lodash/get";

export const adminGetReportQuery = gql`
  query AdminGetReport($id: ID!) {
    adminGetReport(id: $id) {
      id
      reportName
      reportId
      contentId
      contractorIds
      extraReportIds
    }
  }
`;

export const adminGetReportProps = (data = { adminGetReport: {} }) => {
  const reportData = get(data, "adminGetReport", {});

  const { id, reportName, reportId, contentId, contractorIds, extraReportIds } = reportData;

  return {
    id,
    reportName,
    reportId,
    contentId,
    contractorIds: contractorIds || [],
    extraReportIds: extraReportIds || []
  };
};
