import gql from "graphql-tag";

export const getVehiclesQuery = gql`
  query GetVehicles {
    getVehicles {
      id: vehicleId
      vehicleId
      licenseNum
      contractorName
    }
  }
`;

export const getVehiclesDetailsQuery = gql`
  query GetVehicles {
    getVehicles {
      id: vehicleId
      vehicleId
      licenseNum
      contractorId
      contractorName
      phonenumber
      routePlanner
      driverType
      vehicle_containerCount
      vehicle_cubicmeters
      vehicle_ownWeight
      vehicle_capacityKg
      vehicle_axleCount
      vehicle_totalWeight
      trailer_containerCount
      trailer_cubicmeters
      trailer_ownWeight
      trailer_capacityKg
      trailer_axleCount
      trailer_totalWeight
      combination_containerCount
      combination_cubicmeters
      combination_ownWeight
      combination_capacityKg
      combination_axleCount
      combination_totalWeight
      terminal_phonenumber
    }
  }
`;

export const getVehiclesProps = (data = { getVehicles: [] }) => {
  return data.getVehicles.map(item => {
    const { vehicleId, licenseNum, contractorName } = item;
    return {
      vehicleId,
      licenseNum,
      contractorName
    };
  });
};

export const getVehiclesDetailsProps = (data = { getVehicles: [] }) => {
  return data.getVehicles.map(item => {
    const {
      vehicleId,
      licenseNum,
      contractorId,
      contractorName,
      phonenumber,
      routePlanner,
      driverType,
      vehicle_containerCount,
      vehicle_cubicmeters,
      vehicle_ownWeight,
      vehicle_capacityKg,
      vehicle_axleCount,
      vehicle_totalWeight,
      trailer_containerCount,
      trailer_cubicmeters,
      trailer_ownWeight,
      trailer_capacityKg,
      trailer_axleCount,
      trailer_totalWeight,
      combination_containerCount,
      combination_cubicmeters,
      combination_ownWeight,
      combination_capacityKg,
      combination_axleCount,
      combination_totalWeight,
      terminal_phonenumber
    } = item;
    return {
      vehicleId,
      licenseNum,
      contractorId,
      contractorName,
      phonenumber,
      routePlanner,
      driverType,
      vehicle_containerCount,
      vehicle_cubicmeters,
      vehicle_ownWeight,
      vehicle_capacityKg,
      vehicle_axleCount,
      vehicle_totalWeight,
      trailer_containerCount,
      trailer_cubicmeters,
      trailer_ownWeight,
      trailer_capacityKg,
      trailer_axleCount,
      trailer_totalWeight,
      combination_containerCount,
      combination_cubicmeters,
      combination_ownWeight,
      combination_capacityKg,
      combination_axleCount,
      combination_totalWeight,
      terminal_phonenumber
    };
  });
};

export const getCurrentVehicleQuery = gql`
  query currentVehicle {
    currentVehicle @client  {
      vehicleId
      licenseNum
      contractorName
    }
  }
`;

