import React from "react";
import PropTypes from "prop-types";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import Loading from "../../layout/Loading";

const FinalSubmitModal = ({ showModal }) => {

  return (
    <Modal uncloseable={true} open={showModal} onClose={() => {}}>
      <Loading>
        Toimintoa viimeistellään, älä sulje sovellusta
      </Loading>
    </Modal>
  );
}

FinalSubmitModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
};

export default FinalSubmitModal;
