import React from "react";
import PropTypes from "prop-types";

import styles from "./Statusbar.module.scss";

const Statusbar = ({ isOnline, children, awsConfig }) => (
  <div className={`${styles.root} ${(isOnline)? "":styles.offline}`}>
    {children}
    {awsConfig && <span className={styles.ApplicationVersion}>Versio: {awsConfig.ApplicationVersion}</span>}
  </div>
);

Statusbar.propTypes = {
  isOnline: PropTypes.bool,
  children: PropTypes.node,
  awsConfig: PropTypes.any
};

Statusbar.defaultPropTypes = {
  isOnline: true,
  children: null,
  awsConfig: null
};

export default Statusbar;
