import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { string, object } from "yup";
import { Formik, Form, ErrorMessage } from "formik";

import Field from "../input/GreenField";
import Button from "../input/Button";
import Spinner from "../layout/Spinner";

import styles from "./AddReportForm.module.scss";
import SelectTags from "./SelectTags";
import { adminGetContractorsQuery } from "../../api/graphql/adminGetContractors";

const initialValues = {
  reportName: "",
  reportId: "",
  contentId: "",
  contractorIds: [],
  extraReportIds: [],
};

class AddReportForm extends React.Component {
  state = {
    error: null
  };

  validationSchema = object().shape({
    reportName: string().required("Pakollinen kenttä"),
    reportId: string().required("Pakollinen kenttä"),
    contentId: string().required("Pakollinen kenttä")
  });

  onSubmit = reportInfo => {
    const { selectedDrivers, selectedExtraReportIds } = this.state;
    reportInfo.contractorIds = selectedDrivers;
    reportInfo.extraReportIds = selectedExtraReportIds?.filter(id => id !== 'NO_VALUE') || [];
    if (reportInfo.extraReportIds.length == 0) {
      reportInfo.extraReportIds = ['NO_VALUE'];
    }
  
    this.props.onSubmit({
      ...reportInfo,
    });
  };

  handleSelectedDrivers = value => {
    this.setState({selectedDrivers: typeof value === 'string' ? value.split(',') : value})
  }

  handleSelectedExtraReportIds = value => {
    if (value) {
      this.setState({selectedExtraReportIds: typeof value === 'string' ? value.split(',') : value})
    } else {
      this.setState({selectedExtraReportIds: ['NO_VALUE']});
    }
  }

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.validationSchema}
        onSubmit={input => this.onSubmit(input)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formik => {
          const { isSubmitting, isValidating } = formik;
          return (
            <React.Fragment>
              <div className={styles.state}>
                <span>Syötä tiedot</span>
              </div>
              <Form>
                <label htmlFor="reportName" className={styles.label}>
                  Nimi
                </label>
                <Field id="reportName"
                  disabled={isSubmitting} autoFocus
                />
                <ErrorMessage name="reportName" component="div" />
                <br />
                <br />

                <label htmlFor="reportId" className={styles.label}>
                  Raportin ID
                </label>
                <Field
                  id="reportId"
                  disabled={isSubmitting}
                  autoFocus
                />
                <ErrorMessage name="reportId" component="div" />
                <br />
                <br />

                <label htmlFor="contentId" className={styles.label}>
                  Sisällön ID
                </label>
                <Field id="contentId" name="contentId" disabled={isSubmitting} />
                <ErrorMessage name="contentId" component="div" />
                <br />
                <br />

                <label htmlFor="contractorIds" className={styles.label}>
                  Liikennöitsijät
                </label>
                <Query query={adminGetContractorsQuery}>
                  {({ loading, error, data }) => {
                    if (loading) return <Spinner />;
                    if (error) return <Error error={error} />;

                    const { adminGetContractors: allContractors } = data;
                    return <SelectTags
                      id="contractorIds"
                      name="contractorIds"
                      defaultValue={[]}
                      options={allContractors || []}
                      disabled={isSubmitting}
                      onChange={this.handleSelectedDrivers} />
                    }
                  }
                </Query>

                <label htmlFor="extraReportIds" className={styles.extraLabel}>
                  Raportin lisä-ID
                </label>
                <SelectTags
                  id="extraReportIds"
                  name="extraReportIds"
                  defaultValue={[]}
                  options={[]}
                  disabled={isSubmitting}
                  onChange={this.handleSelectedExtraReportIds}
                  freeSolo
                  autoSelect />

                <Button
                  className={styles.submit}
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  uppercase="uppercase"
                >
                  Tallenna
                </Button>
              </Form>
            </React.Fragment>
          );
        }}
      </Formik>
    );
  }
}

AddReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default AddReportForm;
