import PropTypes from 'prop-types';

export const WaybillDataShape= {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      orderedAmount: PropTypes.number.isRequired,
      orderedUnit: PropTypes.string.isRequired,
      commodityName: PropTypes.string.isRequired,
      weight: PropTypes.number,
      items: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
      length: PropTypes.number,
      additionalInfo: PropTypes.array.isRequired,
      transactions: PropTypes.array.isRequired
    })
  ).isRequired,
  transactions: PropTypes.array.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(['load', 'unload']).isRequired,
  customerNum: PropTypes.number.isRequired,
  payerNum: PropTypes.number.isRequired,

  isOngoingLoad: PropTypes.bool.isRequired,
  isOngoingUnload: PropTypes.bool.isRequired,
  allowActions: PropTypes.bool.isRequired,
  onCopyWaybill: PropTypes.func,
  isOnline: PropTypes.bool.isRequired,
  routeId: PropTypes.string.isRequired,
  orderId: PropTypes.string, // orderId is missing in destination view
};
