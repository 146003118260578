import PropTypes from 'prop-types';
import React from 'react';

import LoadingDetailsInput from '../../input/LoadingDetailsInput';
import UnloadingDetailsInput from '../../input/UnloadingDetailsInput';
import Panel from '../../layout/Panel';
import AdditionalInfo from '../AdditionalInfo';
import styles from './OrderTransaction.module.scss';

const OrderTransaction = props => {

  return (
    <Panel className={styles.root} multiple="true">
      <div className={styles.dataTable}>
        <span className={styles.dataProductName}>{props.commodityName}</span>
        <span className={styles.dataAmount}>
          {
            props.type === 'unload' && props.actualAmount
              ? `${Number(props.actualAmount).toLocaleString('fi-FI',)} ${props.actualUnit || ''}`
              : `${
                !isNaN(props.orderedAmount)
                  ? Number(props.orderedAmount).toLocaleString('fi-FI')
                  : '-'
              } ${props.orderedUnit}`
          }
        </span>
        <span className={styles.dataWeight}>
          {
            props.weight
              ? `${Number(props.weight).toLocaleString('fi-FI')} kg`
              : '–'}
        </span>
        <span className={styles.dataItems}>
          {props.items ? props.items : '–'}
        </span>
        <span className={styles.dataDimensions}>
          {
            props.width && props.height && props.length
              ? `${props.width} x ${props.height} x ${props.length}`
              : '–'}
        </span>
      </div>
      <div className={styles.addresses}>
        <div className={styles.destination}>
          {!(props.allowActions && props.isOngoingLoad) && props.waybillNum && (
            <div className={styles.waybill}>Rahtikirja: {props.waybillNum}</div>
          )}
          {!(props.allowActions && props.isOngoingLoad) && props.customerNum && (
            <div className={styles.person}>As numero: {props.customerNum}</div>
          )}
          <div className={styles.address}>
            {[
              props.receiver,
              props.unloadAddress,
              `${props.unloadZipcode} ${props.unloadCity}`,
            ].filter(item => item)
              .join(', ')}
          </div>
          {(props.unloadPerson || props.unloadPhone) && (
            <div className={styles.person}>
              <span>
                {props.unloadPerson && props.unloadPerson}
                {(props.unloadPerson && props.unloadPhone) && ', '}
                {(props.unloadPhone && props.unloadPhone.length > 6) ?
                  <a href={`tel:${props.unloadPhone}`}>{props.unloadPhone}</a> : ''}
              </span>
            </div>
          )}
          {props.unloadAddressAdditionalInfo && (
            <div className={styles.additionalInfo}>
              {props.unloadAddressAdditionalInfo}
            </div>
          )}
        </div>
      </div>
      {props.additionalInfo.length > 0 && (
        <AdditionalInfo>{props.additionalInfo}</AdditionalInfo>
      )}
      {props.allowActions &&
      props.isOngoingLoad ? (
          <LoadingDetailsInput
            onFormCreated={props.onFormCreated}
            onChange={values =>
              props.onUpdateLoad(props.pickupTransactionId, values)
            }
            onCopyWaybill={waybill => props.onCopyWaybill(waybill)}
            loadUnit={props.orderedUnit}
            waybillNum={props.waybillNum}
            actualAmount={
              props.actualAmount !== undefined
                ? props.actualAmount
                : props.orderedAmount || 0
            }
            weightNoteNumberLoading={props.weightNoteNumberLoading}
            container1Load={props.container1Load}
            container2Load={props.container2Load}
            container3Load={props.container3Load}
            container4Load={props.container4Load}
            orderNum={props.orderNum}
            customerNum={props.customerNum}
            refreshStyles={props.refreshStyles}
            fullySigned={props.fullySigned}
          />
        )
        :
        props.isOngoingUnload
          ? (
            <UnloadingDetailsInput
              onChange={values => {
                props.onUpdateUnload(props.unloadTransactionId, values);
              }
              }
              loadUnit={props.orderedUnit}
              waybillNum={props.waybillNum}
              actualAmount={
                props.actualAmount !== undefined
                  ? props.actualAmount
                  : props.orderedAmount || 0
              }
              weightNoteNumberUnloading={props.weightNoteNumberUnloading}
              container1Unload={props.container1Unload}
              container2Unload={props.container2Unload}
              container3Unload={props.container3Unload}
              container4Unload={props.container4Unload}
            />
          )
          : null
      }
    </Panel>
  );
};

OrderTransaction.propTypes = {
  // transaction
  actualAmount: PropTypes.number,
  actualUnit: PropTypes.string,
  orderedAmount: PropTypes.number.isRequired,
  orderedUnit: PropTypes.string.isRequired,
  pickupTransactionId: PropTypes.string.isRequired,
  unloadTransactionId: PropTypes.string.isRequired,
  receiver: PropTypes.string.isRequired,
  unloadAddress: PropTypes.string,
  unloadAddressAdditionalInfo: PropTypes.string,
  unloadCity: PropTypes.string.isRequired,
  unloadPerson: PropTypes.string,
  unloadPhone: PropTypes.string,
  unloadZipcode: PropTypes.string,
  waybillNum: PropTypes.string,
  weightNoteNumberLoading: PropTypes.string,
  weightNoteNumberUnloading: PropTypes.string,
  container1Load: PropTypes.string,
  container2Load: PropTypes.string,
  container3Load: PropTypes.string,
  container4Load: PropTypes.string,
  container1Unload: PropTypes.string,
  container2Unload: PropTypes.string,
  container3Unload: PropTypes.string,
  container4Unload: PropTypes.string,

  // row
  additionalInfo: PropTypes.array,
  commodityName: PropTypes.string.isRequired,
  items: PropTypes.number,
  height: PropTypes.number,
  length: PropTypes.number,
  weight: PropTypes.number,
  width: PropTypes.number,

  // other
  allowActions: PropTypes.bool.isRequired,
  isOngoingLoad: PropTypes.bool.isRequired,
  isOngoingUnload: PropTypes.bool.isRequired,
  onUpdateLoad: PropTypes.func.isRequired,
  onUpdateUnload: PropTypes.func.isRequired,
  onFormCreated: PropTypes.func.isRequired,
  onCopyWaybill: PropTypes.func.isRequired,
  refreshStyles: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,

  orderNum: PropTypes.number.isRequired,
  customerNum: PropTypes.number.isRequired,
  fullySigned: PropTypes.bool.isRequired,
};

export default OrderTransaction;
