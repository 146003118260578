import gql from "graphql-tag";
import get from "lodash/get";

export const adminAddReportMutation = gql`
  mutation AdminAddReport(
    $reportName: String!
    $reportId: String
    $contentId: String
    $contractorIds: [String]
    $extraReportIds: [String]
  ) {
    adminAddReport(
      reportName: $reportName
      reportId: $reportId
      contentId: $contentId
      contractorIds: $contractorIds
      extraReportIds: $extraReportIds
    ) {
      id
      reportName
      reportId
      contentId
      contractorIds
      extraReportIds
    }
    addReportToCache @client
  }
`;

export const adminAddReportProps = (data = { addReport: {} }) => {
  const reportData = get(data, "adminAddReport", {});

  const { id, reportName, reportId, contentId, contractorIds, extraReportIds } = reportData;

  return {
    id,
    reportName,
    reportId,
    contentId,
    contractorIds,
    extraReportIds
  };
};
