import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Panel from '../../layout/Panel';
import AdditionalInfo from '../AdditionalInfo';
import { WaybillDataShape } from './shapes';
import styles from './WaybillOrderInformation.module.scss';
import WaybillOrderTransaction from './WaybillOrderTransaction';

class WaybillOrderInformation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      rows,
      transactionIds,
      customerNum,
      customerName,
      payerNum,
      type,
      allowActions,
      isOngoingLoad,
      isOngoingUnload,
      isOnline,
    } = this.props.waybillData;

    const commonInfo = this.props.commonInfo;

    return (
      <Panel className={styles.panel}>
        <b>TILAUKSEN TIEDOT</b>

        <div className={styles.addresses}>
          <div className={styles.destination}>
            <div className={styles.waybill}><b>Kuljetusasiakirjan numero:</b> {commonInfo.waybillNum}</div>
            <div className={styles.customer}><b>Asiakkaan nimi:</b> {customerName}</div>
            <div className={styles.address}>
              <b>Lähettäjä:</b> {
                [
                  commonInfo.sender,
                  commonInfo.pickupAddress,
                  `${commonInfo.pickupZipcode} ${commonInfo.pickupCity}`,
                ]
                  .filter(item => item)
                  .join(', ')
              }
            </div>
            <div className={styles.address}>
              <b>Vastaanottaja:</b> {
                [
                  commonInfo.receiver,
                  commonInfo.unloadAddress,
                  `${commonInfo.unloadZipcode} ${commonInfo.unloadCity}`,
                ]
                  .filter(item => item)
                  .join(', ')
              }
            </div>
            {(commonInfo.pickupPerson || commonInfo.pickupPhone) && (
              <div className={styles.person}>
                <span>
                  <b>Yhteyshenkilö (lähettäjä):</b> {commonInfo.pickupPerson && commonInfo.pickupPerson}
                  {(commonInfo.pickupPerson && commonInfo.pickupPhone) && ', '}
                  {(commonInfo.pickupPhone && commonInfo.pickupPhone.length > 6) ?
                    <a href={`tel:${commonInfo.pickupPhone}`}>{commonInfo.pickupPhone}</a> : ''}
                </span>
              </div>
            )}
            {(commonInfo.unloadPerson || commonInfo.unloadPhone) && (
              <div className={styles.person}>
                <span>
                  <b>Yhteyshenkilö (vastaanottaja):</b> {commonInfo.unloadPerson && commonInfo.unloadPerson}
                  {(commonInfo.unloadPerson && commonInfo.unloadPhone) && ', '}
                  {(commonInfo.unloadPhone && commonInfo.unloadPhone.length > 6) ?
                    <a href={`tel:${commonInfo.unloadPhone}`}>{commonInfo.unloadPhone}</a> : ''}
                </span>
              </div>
            )}
            {commonInfo.unloadAddressAdditionalInfo && (
              <div className={styles.additionalInfo}>
                {commonInfo.unloadAddressAdditionalInfo}
              </div>
            )}
          </div>
        </div>
        {commonInfo.additionalInfo && commonInfo.additionalInfo.length > 0 && (
          <AdditionalInfo>{commonInfo.additionalInfo}</AdditionalInfo>
        )}

        <div className={styles.dataHeader}>
          <span className={styles.dataProductName}>Tuotenimi</span>
          <span className={styles.dataAmountLoaded}>Toteutunut</span>
          <span className={styles.dataAmount}>Tilattu</span>
          <span className={styles.dataWeight}>Kilot</span>
          <span className={styles.dataItems}>Kollit</span>
          <span className={styles.dataDimensions}>Mitat</span>
        </div>

        <div>
          {rows && rows.map(row => {
            // Build a key for each row. The row does not included a UUID, but transactions do
            // This ID needs to be kept consistent so that focus is kept on any form fields whilst a user is typing (see defect FTL-186)
            const rowKey = row.transactions.reduce((ids, transaction) => {
              return `${ids}${transaction.pickupTransactionId}${transaction.unloadTransactionId}`;
            }, '');

            return (
              <React.Fragment key={rowKey}>
                {row.transactions
                  .filter(
                    transaction =>
                      transactionIds.includes(transaction.unloadTransactionId) ||
                      transactionIds.includes(transaction.pickupTransactionId),
                  )
                  .map(transaction => (
                    <WaybillOrderTransaction
                      key={`${transaction.pickupTransactionId}${
                        transaction.unloadTransactionId
                      }`}
                      additionalInfo={row.additionalInfo}
                      commodityName={row.commodityName}
                      items={row.items}
                      height={row.height}
                      length={row.length}
                      weight={row.weight}
                      width={row.width}
                      actualAmount={transaction.actualAmount}
                      actualUnit={transaction.actualUnit}
                      orderedAmount={transaction.orderedAmount}
                      orderedUnit={transaction.orderedUnit}
                      pickupTransactionId={transaction.pickupTransactionId}
                      unloadTransactionId={transaction.unloadTransactionId}
                      sender={transaction.sender}
                      pickupAddress={transaction.pickupAddress}
                      pickupAddressAdditionalInfo={transaction.pickupAddressAdditionalInfo}
                      pickupCity={transaction.pickupCity}
                      pickupPerson={transaction.pickupPerson}
                      pickupPhone={transaction.pickupPhone}
                      pickupZipcode={transaction.pickupZipcode}
                      receiver={transaction.receiver}
                      unloadAddress={transaction.unloadAddress}
                      unloadAddressAdditionalInfo={transaction.unloadAddressAdditionalInfo}
                      unloadCity={transaction.unloadCity}
                      unloadPerson={transaction.unloadPerson}
                      unloadPhone={transaction.unloadPhone}
                      unloadZipcode={transaction.unloadZipcode}
                      waybillNum={transaction.waybillNum}
                      customerNum={customerNum}
                      payerNum={payerNum}
                      type={type}
                      allowActions={allowActions}
                      isOngoingLoad={isOngoingLoad}
                      isOngoingUnload={isOngoingUnload}
                      onCopyWaybill={(/*pickupTransactionId, values*/) => {
                      }}
                      onFormCreated={(/*formik*/) => {
                      }}
                      weightNoteNumberLoading={transaction.weightNoteNumberLoading}
                      weightNoteNumberUnloading={transaction.weightNoteNumberUnloading}
                      isOnline={isOnline}
                    />
                  ))}
              </React.Fragment>
            );
          })}
        </div>
      </Panel>
    );
  }
}

WaybillOrderInformation.propTypes = {
  waybillData: PropTypes.shape(WaybillDataShape).isRequired,
  commonInfo: PropTypes.object.isRequired,
};

export default WaybillOrderInformation;
