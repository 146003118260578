import gql from "graphql-tag";

export const adminGetReportsQuery = gql`
  query AdminGetReports {
    adminGetReports {
      id
      reportName
      reportId
      contentId
      contractorIds
      extraReportIds
    }
  }
`;

export const adminGetReportsProps = (data) => {
  return data.adminGetReports.map(item => ({
    id: item.id,
    reportName: item.reportName,
    reportId: item.reportId,
    contentId: item.contentId,
    contractorIds: item.contractorIds,
    extraReportIds: item.extraReportIds ?? []
  }));
};
