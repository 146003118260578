import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NoSleep from "nosleep.js";

import Button from "../components/input/Button";
import Modal from "../components/layout/Modal";

const StayAwake = (props) => {
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  useEffect(() => {
    if (hasUserInteracted) return;

    document.body.addEventListener("mousedown", onUserInteraction);
    document.addEventListener("visibilitychange", onVisiblityChange);
  
    return () => {
      document.body.removeEventListener("mousedown", onUserInteraction);
      document.removeEventListener("visibilitychange", onVisiblityChange);
    };
  });

  const onUserInteraction = () => {
    document.body.removeEventListener("mousedown", onUserInteraction);
    setHasUserInteracted(true);
    toggleNoSleep(hasUserInteracted, true);
  };

  const onVisiblityChange = () => {
    toggleNoSleep(!document.hidden);
  };

  const toggleNoSleep = (noSleep) => {
    // nosleep requires user to have interacted with the app before initialization
    if (!hasUserInteracted) return;
  
    if (noSleep) {
      noSleep && this.noSleep.disable();
      noSleep = new NoSleep();
      noSleep.enable();
    } else {
      noSleep && this.noSleep.disable();
      noSleep = null;
    }
  }
  

  


  if (props.geolocation && props.geolocation.geolocated && !hasUserInteracted) {
    return (
      <Modal uncloseable>
        GPS-seuranta käynnissä.
        <br />
        <br />
        <Button onClick={props.geolocation.restart}>OK</Button>
      </Modal>
    );
  }
  return <> </>;
}

StayAwake.propTypes = {
  geolocation: PropTypes.shape({
    restart: PropTypes.func.isRequired,
    geolocated: PropTypes.bool.isRequired,
    getLocation: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
  }),
};

StayAwake.defaultProps = {
  geolocation: {
    geolocated: false
  }
};

export default StayAwake;
