import Amplify, { Auth } from "aws-amplify";

let currentUser;
let currentUserInfo;
let onSignIn;
let onSignOut;

export default {
  configure(config, onSignInCallback, onSignOutCallback) {
    onSignIn = onSignInCallback;
    onSignOut = onSignOutCallback;
    // set up Amplify as it will be used for authenticating the user
    Amplify.configure({
      Auth: config
    });
  },

  async signIn(username, password) {
    try {
      // username is not case-sensitive, so always lower case
      currentUser = await Auth.signIn(username.trim().toLowerCase(), password);
      if (onSignIn) {
        await onSignIn(username, password);
      }
    } catch (error) {
      throw error;
    }
  },

  async requestPasswordReset(username) {
    try {
      return await Auth.forgotPassword(username);
    } catch (error) {
      throw error;
    }
  },

  async verifyPasswordReset(username, verificationCode, newPassword) {
    try {
      return await Auth.forgotPasswordSubmit(
        username,
        verificationCode,
        newPassword
      );
    } catch (error) {
      throw error;
    }
  },

  async setNewPassword(password) {
    try {
      return await Auth.completeNewPassword(currentUser, password);
    } catch (error) {
      throw error;
    }
  },

  async changePassword(oldPassword, newPassword) {
    try {
      const user = await this.getCurrentUser();
      return await Auth.changePassword(user, oldPassword, newPassword);
    } catch (error) {
      throw error;
    }
  },

  async refreshSession(){
    const session = await this.getCurrentSession(); // Calling this refreshes the authorization token
    const refreshToken = session.getRefreshToken();
    const user = await this.getCurrentUser();
    const refreshedSession = await new Promise((resolve) => user.refreshSession(refreshToken, (err, session) => {
      if(err){
        resolve(false);
      }
      resolve(session);
    }));
    if(refreshedSession){
      user.setSignInUserSession(refreshedSession);
      return refreshedSession;
    }
    else {
      return this.signOut();
    }
  },

  async getCurrentUser() {
    try {
      if (!currentUser) {
        currentUser = await Auth.currentAuthenticatedUser();
      }
      return currentUser;
    } catch (error) {
      return null;
    }
  },

  async getCurrentUserInfo() {
    try {
      if (!currentUserInfo) {
        currentUserInfo = await Auth.currentUserInfo();
      }
      return currentUserInfo;
    } catch (error) {
      return null;
    }
  },

  async getCurrentSession() {
    try {
      return await Auth.currentSession();
    } catch (error) {
      return null;
    }

  },

  async signOut() {
    const user = await this.getCurrentUser();
    if (onSignOut) {
      await onSignOut();
    }
    await user.signOut();
    //console.log("cleaning local storage");
    //localStorage.clear();
    window.location.href = "/";
  },

  async signUp({ username, password, email }) {
    try {
      return await Auth.signUp({
        username,
        password,
        attributes: {
          email // optional
          // phone_number,   // optional - E.164 number convention
          // other custom attributes
        },
        validationData: [] //optional
      });
    } catch (error) {
      console.log(error);
    }
  }
};
