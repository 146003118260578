import React from "react";
import PropTypes from "prop-types";
import { Mutation, withApollo } from "react-apollo";
import AWSAppSyncClient from "aws-appsync";

import CurrentUser from "../data/CurrentUser";
import AddReportForm from "../../components/reports/AddReportForm";
import { adminAddReportMutation } from "../../api/graphql/adminAddReport";
import Modal from "../../components/layout/Modal";
import { navigate } from "@reach/router";

class AddReport extends React.Component {
  render() {
    return (
      <CurrentUser>
        {currentUser => {
          const { isAdmin } = currentUser;

          return isAdmin ? (
            <Mutation mutation={adminAddReportMutation}
              onCompleted={() => navigate("/reports")}>
              {(addReport) => {
                return (
                  <Modal>
                    <h3>Uusi raportti</h3>
                    <AddReportForm
                      isAdmin={isAdmin}
                      onSubmit={reportInfo => {
                        addReport({
                          variables: {
                            reportName: reportInfo.reportName,
                            reportId: reportInfo.reportId,
                            contentId: reportInfo.contentId,
                            contractorIds: reportInfo.contractorIds || [],
                            extraReportIds: reportInfo.extraReportIds || []
                          }
                        });
                      }}
                    />
                  </Modal>    
                )
              }}
            </Mutation>
          ) : null;
        }}
      </CurrentUser>
    )
  }
}

AddReport.propTypes = {
  client: PropTypes.instanceOf(AWSAppSyncClient).isRequired
};

export default withApollo(AddReport);
