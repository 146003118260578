import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { navigate } from "@reach/router";
import { withApollo } from "react-apollo";
import { TableRow, TableCell, IconButton, Table } from "@material-ui/core";
import { adminGetReportsQuery } from "../../api/graphql/adminGetReports";
import { adminDeleteReportMutation } from "../../api/graphql/adminDeleteReport";
import { getReportsQuery } from "../../api/graphql/getReports";

import trashCanSVG from "../routes/assets/trashcan.svg";
import viewSVG from "../routes/assets/order.svg";
import styles from "./ReportListRow.module.scss";

const TrashCanButton = ({ onClick, alt, width, height, isActive }) => (
  <IconButton
    className={`${styles.iconButton} ${!isActive && styles.iconButtonInactive}`}
    onClick={(event) => {
      event.stopPropagation();
      isActive && onClick && onClick();
    }}
  >
    <img 
      src={trashCanSVG} 
      alt={alt || "Delete"} 
      width={width} 
      height={height} 
    />
  </IconButton>
);

const ViewButton = ({ onClick, alt, width, height, isActive }) => (
  <IconButton
    className={`${styles.iconButton} ${!isActive && styles.iconButtonInactive}`}
    onClick={(event) => {
      event.stopPropagation();
      isActive && onClick && onClick();
    }}
  >
    <img 
      src={viewSVG} 
      alt={alt || "View"} 
      width={width} 
      height={height} 
    />
  </IconButton>
);

const ReportListRow = ({ client, loading, report, isAdmin, isInvalid }) => {
  const { id, reportName, reportId, contentId } = report;
  const getReports = !isAdmin ? getReportsQuery : adminGetReportsQuery;
  const getReportsDataName = !isAdmin ? "getReports" : "adminGetReports";

  const [isDeleting, setDeleting] = useState(false);

  const deleteReport = () => {
    client.mutate({
      mutation: adminDeleteReportMutation,
      variables: { id },
      onError: () => setDeleting(false),
      update: (cache, { data }) => {
        const id = get(data, ["adminDeleteReport", "id"], null);
        if(id){
          const allReports = get(cache.readQuery({ query: getReports }), getReportsDataName, []);
          cache.writeQuery({
            query: getReports,
            data: { [getReportsDataName]: allReports.filter(e => `${e.id}` !== `${id}`) }
          });
        }
      }
    });
  }

  return (
    <TableRow
      hover
      onClick={!loading && id && !isInvalid && isAdmin ? () => navigate("/reports/" + id) : null}
      tabIndex={-1}
      className={`
        ${styles.tableRow}
        ${!loading && isInvalid ? styles.invalid : ""}
        ${loading ? styles.loading : ""}
        ${isDeleting ? styles.deleting : ""}
      `} 
    >
      <React.Fragment>
        <TableCell component="th" scope="row">{reportName}</TableCell>
        {isAdmin && (
          <>
            <TableCell>{reportId}</TableCell>
            <TableCell>{contentId}</TableCell>
          </>
        )}
        <TableCell className={styles.action}>
          <ViewButton
            onClick={() => {
              navigate('/reports/' + id + '/view');
            }}
            isActive={!loading}
            width={20}
            height={20}
          />
          {(!loading && isAdmin) && (
            <>
              <TrashCanButton
                onClick={() => {
                  setDeleting(true);
                  deleteReport();
                }}
                isActive={isAdmin && !loading && !isDeleting}
                width={20}
                height={20}
              />
            </>
          )}
        </TableCell>

      </React.Fragment>
    </TableRow>
  );
};

ReportListRow.propTypes = {
  loading: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isInvalid: PropTypes.bool,
  report: PropTypes.shape({
    id: PropTypes.string,
    reportName: PropTypes.string,
    reportId: PropTypes.string,
    contentId: PropTypes.string,
    contractorIds: PropTypes.arrayOf(PropTypes.string),
    extraReportIds: PropTypes.arrayOf(PropTypes.string)
  })
};

export default withApollo(ReportListRow);
