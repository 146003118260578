import React, { useState } from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { selectVehicleMutation } from "../api/graphql/selectVehicle";
import Panel from "../components/layout/Panel";
import Button from "../components/input/Button";
import DigitInput from "../components/input/DigitInput";
import Error from "../components/layout/Error";
import Vehicles from "../containers/data/Vehicles";

import styles from "./VehicleSelection.module.scss";

const VehicleSelection = ({ onSelected, goToReports }) => {
  const [licenseFilter, setLicenseFilter] = useState("");

  return (
    <Vehicles>
      {({ vehicles }) => (
        <Mutation mutation={selectVehicleMutation}>
          {selectVehicle => (
            <div className={styles.root}>
              {vehicles.length > 0 ? (
                <>
                  <h2>Mitä rekkaa ajat?</h2>
                  <h3>Hae rekka rekisterinumerolla.</h3>
                  <DigitInput
                    license={true}
                    onChange={e =>
                      this.setState({ licenseFilter: e.target.value })
                  }
                  />
                  <Panel>
                    {vehicles
                      .filter(vehicle => {
                        if (licenseFilter.length > 0) {
                          return vehicle.licenseNum
                            .replace('-', '')
                            .includes(licenseFilter.toUpperCase());
                          }
                        return true;
                      })
                      .map(vehicle => (
                        <div
                          className={styles.vehicle}
                          onClick={() => {
                            selectVehicle({
                              variables: {
                                vehicleId: vehicle.vehicleId,
                                licenseNum: vehicle.licenseNum,
                                contractorName: vehicle.contractorName,
                              },
                            });
                            if (onSelected) onSelected();
                          }}
                          key={vehicle.vehicleId}
                        >
                          {vehicle.licenseNum}
                        </div>
                      ))}
                  </Panel>
                </>
              ) : (
                <>
                  <Error
                    error={{
                      message:
                        'Liikennöitsijällä ei ole aktiivisia ajoneuvoja.',
                    }}
                  />
                  <Button onClick={goToReports}>Raportit</Button>
                </>
              )}
            </div>
          )}
        </Mutation>
      )}
    </Vehicles>
  );
}

export default VehicleSelection;
