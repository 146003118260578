import React from "react";
import { Router } from "@reach/router";

import Reports from "./Reports";
import AddReport from "./AddReport";
import Report from "./Report";
import PowerBI from "./PowerBI";

const ReportManagement = ({ refreshStyles }) => {
  return (
    <Router>
      <Reports path="/" refreshStyles={refreshStyles}>
        <AddReport path="add" />
        <Report path=":id" />
        <PowerBI path=":id/view" />
      </Reports>
    </Router>
  )
};

ReportManagement.propTypes = {};

export default ReportManagement;
