import React from "react";
import { Mutation } from "react-apollo";

import { selectDeviceTypeMutation } from "../api/graphql/selectDeviceType";
import DeviceType from "../enum/DeviceType";

import Button from "./input/Button";
import Panel from "./layout/Panel";
import CenteredContent from "./layout/CenteredContent";

import styles from "./DeviceTypeSelection.module.scss";

class DeviceTypeSelection extends React.Component {
  render() {
    return (
      <Mutation mutation={selectDeviceTypeMutation}>
        {selectDeviceType => (
          <CenteredContent>
            <div className={styles.root}>
              <Panel className={styles.mode1}>
                <h3>Käytä tätä laitetta kuljetuksen seurantaan</h3>
                <p>
                  Valitse tämä, jos kirjaudut rekassa olevalla laitteella.
                  Tällöin sovellusta käytetään kuljetuksen seurantaan, toteumien 
                  kuittaukseen ja kuljetusasiakirjakuvien palautukseen.
                  Huomioithan, että sovelluksen tulee olla päällä koko ajan.
                </p>

                <div>
                  <Button
                    color="green"
                    onClick={() =>
                      selectDeviceType({
                        variables: { deviceType: DeviceType.VEHICLE }
                      })
                    }
                  >
                    Käytä kuljetuksen seurantaa
                  </Button>
                </div>
              </Panel>
              <div className={styles.mode2}>
                <h3>Älä käytä tätä laitetta kuljetuksen seurantaan</h3>
                <p>
                  Valitse tämä, jos esimerkiksi kirjaudut omalla
                  puhelimellasi tai omalla tabletillasi sovellukseen. Tällöin
                  sovellusta ei käytetä kuljetuksen seurantaan.
                </p>

                <div>
                  <Button
                    onClick={() =>
                      selectDeviceType({
                        variables: { deviceType: DeviceType.PERSONAL }
                      })
                    }
                    color="ghost"
                  >
                    Ei kuljetuksen seurantaa
                  </Button>
                </div>
              </div>
            </div>
          </CenteredContent>
        )}
      </Mutation>
    );
  }
}

export default DeviceTypeSelection;
