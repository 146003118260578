import retrieveLocalData from "./retrieveLocalData";
import storeLocalData from "./storeLocalData";
import parseNumber from "./parseNumber";
import * as R from 'ramda';

export default (
    transactionId,
    { waybill, amountLoaded, weightNoteNumberLoading, weightNoteNumberUnloading,
      container1Load, container2Load, container3Load, container4Load,
      container1Unload, container2Unload, container3Unload, container4Unload,
      senderNameClarification, senderTimestamp, senderSignatureNotAvailable,
      driverNameClarification, driverTimestamp,
      receiverNameClarification, receiverTimestamp, receiverSignatureNotAvailable,
      signed, signedOnLoad, signedOnUnload
    }
  ) => {
    if (!transactionId) {
      console.log("Cannot update transactionLoadInputs with null transaction key. Waybill ", waybill);
      return;
    }

    // Use cache as basis
    let transactionInputs = {
      ...retrieveLocalData("transactionLoadInputs")
    };

    //Overwrite values if new value is provided, otherwise leave as is

    if(waybill !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'waybill']), waybill, transactionInputs);
    }
    if(amountLoaded !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'amountLoaded']), parseNumber(amountLoaded), transactionInputs);
    }
    if(weightNoteNumberLoading !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'weightNoteNumberLoading']), weightNoteNumberLoading, transactionInputs);
    }
    if(weightNoteNumberUnloading !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'weightNoteNumberUnloading']), weightNoteNumberUnloading, transactionInputs);
    }

    if(container1Load !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container1Load']), container1Load, transactionInputs);
    }
    if(container2Load !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container2Load']), container2Load, transactionInputs);
    }
    if(container3Load !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container3Load']), container3Load, transactionInputs);
    }
    if(container4Load !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container4Load']), container4Load, transactionInputs);
    }

    if(container1Unload !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container1Unload']), container1Unload, transactionInputs);
    }
    if(container2Unload !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container2Unload']), container2Unload, transactionInputs);
    }
    if(container3Unload !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container3Unload']), container3Unload, transactionInputs);
    }
    if(container4Unload !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'container4Unload']), container4Unload, transactionInputs);
    }

    if(senderNameClarification !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'senderNameClarification']), senderNameClarification, transactionInputs);
    }
    if(senderTimestamp !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'senderTimestamp']), senderTimestamp, transactionInputs);
    }
    if(senderSignatureNotAvailable !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'senderSignatureNotAvailable']), senderSignatureNotAvailable, transactionInputs);
    }
    if(driverNameClarification !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'driverNameClarification']), driverNameClarification, transactionInputs);
    }
    if(driverTimestamp !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'driverTimestamp']), driverTimestamp, transactionInputs);
    }
    if(receiverNameClarification !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'receiverNameClarification']), receiverNameClarification, transactionInputs);
    }
    if(receiverTimestamp !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'receiverTimestamp']), receiverTimestamp, transactionInputs);
    }
    if(receiverSignatureNotAvailable !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'receiverSignatureNotAvailable']), receiverSignatureNotAvailable, transactionInputs);
    }
    if(signed !== undefined){
    	transactionInputs = R.set(R.lensPath([transactionId, 'signed']), signed, transactionInputs);
    }
    if(signedOnLoad !== undefined){
      transactionInputs = R.set(R.lensPath([transactionId, 'signedOnLoad']), signedOnLoad, transactionInputs);
    }
    if(signedOnUnload !== undefined){
      transactionInputs = R.set(R.lensPath([transactionId, 'signedOnUnload']), signedOnUnload, transactionInputs);
    }

    storeLocalData("transactionLoadInputs", transactionInputs);
};

