import gql from "graphql-tag";

export const adminUpdateReportMutation = gql`
  mutation AdminUpdateReport(
    $id: ID!
    $reportName: String!
    $reportId: String
    $contentId: String
    $contractorIds: [String]
    $extraReportIds: [String]
  ) {
    adminUpdateReport(
      id: $id
      reportName: $reportName
      reportId: $reportId
      contentId: $contentId
      contractorIds: $contractorIds
      extraReportIds: $extraReportIds
    ) {
      id
      reportName
      reportId
      contentId
      contractorIds
      extraReportIds
    }
  }
`;
