import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link, navigate } from "@reach/router";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import deepEqual from "lodash/isEqual";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { roadHazards } from "../../app.config";
import retrieveLocalData from "../../utils/retrieveLocalData";
import storeLocalData from "../../utils/storeLocalData";
import Loading from '../layout/Loading.js';
import Modal from "../layout/Modal";
import LogRoadHazardButton from "../roadhazards/LogRoadHazardButton";
import getDestinationsByDates from "../routes/utils/getDestinationsByDate";
import AddressSearch from './AddressSearch';
import styles from "./HEREMap.module.scss";
import PositionMarker from "./PositionMarker";
import roadHazardPin from "./roadHazardPin";
import Truck from "./Truck";
import { DumbCache } from "./utils/Cache.js";
import Coords from "./utils/Coords.js";
import GeocodeCache from "./utils/GeocodeCache.js";
import calcDistance from "../routes/utils/calcDistance";
import Waypoint from "./Waypoint";

import H from "@here/maps-api-for-javascript";

const geoCache = new GeocodeCache();
const routeCache = new DumbCache();



// TODO: Cache for map objects (Route polylines, markers, ...)
class HEREMap extends Component {

  // FolloTypes define on which the map is centered on
  static followTypes = {
    none: 0,
    truck: 1,
    currentRoute: 2,
    approach: 3
  }

  constructor(props) {
    super(props);

    let lat = 60.2030859, lng = 24.9226061;
    const currentLocation = props.geolocation && props.geolocation.getLocation();
    if (currentLocation) {
      lat = currentLocation.latitude;
      lng = currentLocation.longitude;
    }
    const center = new Coords(lat, lng);

    this.accuracy = currentLocation ? currentLocation.accuracy || 0 : 0;
    this.mounted = false; // Boolean value of component mounting status
    this.startInitialized = false; // Boolean value of map initialization
    this.center = center;
    this.zoom = 16;
    this.platform = null; // Here Maps instance
    this.defaultLayers = null;
    this.truckLayer = null;
    this.roadHazardsLayer = null;
    this.roadHazardsGroup = null;
    this.positionMarkerLayer = null;
    this.positionMarkerGroup = null;
    this.addressMarkerLayer = null;
    this.addressMarkerGroup = null;
    this.waypointMarkerLayer = null;
    this.waypointMarkerGroup = null;
    this.mapEvents = null;
    this.map = null;
    this.snackbarTimeout = null;
    this.waypoints = [];
    this.truck = new Truck(center, { zIndex: 100 });
    this.accuracyCircle = new H.map.Circle(
      // The central point of the circle
      center,
      // The radius of the circle in meters
      this.accuracy,
      {
        style: {
          strokeColor: 'rgba(36, 121, 179, 0.4)', // Color of the perimeter
          lineWidth: 2,
          fillColor: 'rgba(36, 121, 179, 0.2)'  // Color of the circle
        }
      }
    );
    this.currentRoute = new H.map.Polyline(
      new H.geo.LineString([0, 0, 0, 0, 0, 0]),
      { style: { strokeColor: "rgba(0, 0, 255, 0.7)", lineWidth: 1.5 } }
    );
    this.autoCentering = true;

    let follow = HEREMap.followTypes.truck;
    let followType = retrieveLocalData("followType");
    if (isEmpty(followType)) {
      setTimeout(() => this.setFollow(follow, true), 1000);
    } else {
      follow = followType.type;
    }

    let terrain = 'normal';
    let terrainMode = retrieveLocalData("terrainMode");
    if (!isEmpty(terrainMode)) {
      terrain = terrainMode.mode;
    }

    this.state = {
      app_apikey: props.app_apikey,
      center: center,
      destinations: null,
      isReady: false,
      //showTrafficIncidents: false, // Disabling as this is expensive, app will use Digitraffic API instead
      currentWaypointAccuracy: null,
      showWaypointAccuracyPopup: false,
      follow: follow,
      mapMode: props.mapMode,
      terrainMode: terrain,
      mapMenuAnchorEl: null,
      snackbarOpen: false
    };

    this.updateTruckPosition = this.updateTruckPosition.bind(this);
    //this.toggleTrafficIncidents = this.toggleTrafficIncidents.bind(this); // Disabling as this is expensive, app will use Digitraffic API instead
    this.onWindowResize = this.onWindowResize.bind(this);
    this.onMapDragStart = this.onMapDragStart.bind(this);
    this.onMapViewChange = this.onMapViewChange.bind(this);
    this.onMapViewChangeEnd = this.onMapViewChangeEnd.bind(this);
    this.onMapLongPress = this.onMapLongPress.bind(this);
    this.onGeolocationUpdate = this.onGeolocationUpdate.bind(this);
  }

  /**
   * Leave approach mode
   * If the user has pressed the zoom ui element, check for follow mode,
   * inform the user about on possible follow mode change and change it
   * @param {*} evt 
   */
  leaveApproachMode(evt){

    if(this.state.follow === HEREMap.followTypes.approach){
      this.setFollow(HEREMap.followTypes.truck);
      this.props.showSnackbar();
      this.snackbarTimeout = setTimeout(this.props.handleSnackbarClose, 5000);
    }
    
  }

  /**
   * onWindowResize
   * Triggered by window "resize" eventListener
   * Will resize map viewport
   * @param {object} evt 
   */
  onWindowResize(evt) {
    this.map.getViewPort().resize();
  }

  /**
   * onMapDragStart
   * Triggered by Here Maps "dragstart" eventListener
   * Will disable follow route/truck while user drags the map
   * @param {object} evt
   */
  onMapDragStart(evt) {
    this.setFollow(HEREMap.followTypes.none);
  }

  /**
   * onMapViewChange
   * Triggered by Here Maps "mapviewchange" eventListener
   * Will disable follow route/truck while mapviewchange is in progress
   * @param {object} evt 
   */
  onMapViewChange(evt) {
    if (
      this.state.isReady &&
      !this.autoCentering &&
      (this.state.follow !== HEREMap.followTypes.truck && this.state.follow !== HEREMap.followTypes.approach) &&
      this.state.follow !== HEREMap.followTypes.currentRoute
    ) {
      this.setFollow(HEREMap.followTypes.none);
    }
  }

  /**
   * onMapViewChangeEnd
   * Change route style according to zoom amount
   * Follow (center to route/truck) if set
   * @param {object} evt 
   */
  onMapViewChangeEnd(evt) {

    // Set stroke opacity based on user zoom level
    if (this.map.getZoom() > 13) {
      this.currentRoute.setStyle({ strokeColor: "rgba(0, 0, 255, 0.4)", lineWidth: 3 });
    }
    else if(this.map.getZoom() > 8){
      this.currentRoute.setStyle({ strokeColor: "rgba(0, 0, 255, 0.7)", lineWidth: 3 });
    }
    else {
      this.currentRoute.setStyle({ strokeColor: "rgba(0, 0, 255, 0.7)", lineWidth: 1.5 });
    }

    if (this.state.follow !== HEREMap.followTypes.none) {
      this.follow();
    }

    this.autoCentering = false;
    this.zoom = this.map.getZoom();

  }

  onMapLongPress(evt) {
    var coord = this.map.screenToGeo(evt.currentPointer.viewportX,
            evt.currentPointer.viewportY);
    const location = new Coords(coord.lat, coord.lng);
    storeLocalData("userAddedWaypointLocation", location);
    this.userAddedWaypoint = this.addWaypointMarkerToMap(location);
    this.updateTruckPosition(this.center, true);
  }

  UNSAFE_componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("resize", this.onWindowResize);
    this.map.removeEventListener("dragstart", this.onMapDragStart);
    this.map.removeEventListener("mapviewchange", this.onMapViewChange);
    this.map.removeEventListener("mapviewchangeend", this.onMapViewChangeEnd);
    this.map.removeEventListener("longpress", this.onMapLongPress);
    this.props.geolocation && this.props.geolocation.unsubscribe(this.onGeolocationUpdate);
  }

  setBaseLayerStyle(map) {
    var provider = map.getBaseLayer().getProvider();
    var style = new H.map.Style('/truck.yaml',
      'https://js.api.here.com/v3/3.1/styles/omv/');
    provider.setStyle(style);
    this.interleave(map);
  }

  /**
   * Programmatically touch e.g. font sizes on all zoom levels without manually altering yaml file for each level
   * @param {*} map 
   */
  interleave(map) {
    var provider = map.getBaseLayer().getProvider();
    var style = provider.getStyle();

    const changeLabel = function(lblSize, mult) {
      let size = lblSize;

      if(!size) return lblSize

      // Resize label or labels
      if(!Array.isArray(size)) {
        let sz = parseFloat(size.replace("px", ""));
        size = Math.round((sz * mult)*100) / 100 + "px";
      } else {
        for(var i=0,len=size.length; i < len; i++) {
          let strSize = String((size[i][1]));
          if(strSize !== 0){
            let sz = parseFloat(strSize.replace("px", ""));
            size[i][1] = Math.round((sz * mult)*100) / 100 + "px";
          }
        }
      }
      return size;
    }

    var changeListener = () => {
      if (style.getState() === window.H.map.render.webgl.Style.State.READY) {
        style.removeEventListener('change', changeListener);

        // Resize places labels
        let places = style.getProperty("global.places");
        // eslint-disable-next-line
        for (const [k, placeKind] of Object.entries(places)) {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(placeKind)) {
             if(value.font){
              value.font.size = changeLabel(value.font.size, 2.5);
              continue;
            }
             if(value.label && value.label.font){
              value.label.font.size = changeLabel(value.label.font.size, 2.5);
              continue;
            }
          }
        }
        style.setProperty("global.places", places, true);

        // Resize road labels
        let roads = style.getProperty("global.road");
        // eslint-disable-next-line
        for (const [k, placeKind] of Object.entries(roads)) {
          // eslint-disable-next-line
          for (const [key, value] of Object.entries(placeKind)) {
            if(value.font){
              value.font.size = changeLabel(value.font.size, 1.8);
              continue;
            }
            if(value.label && value.label.font){
              value.label.font.size = changeLabel(value.label.font.size, 1.8);
              continue;
            }
          }
        }
        style.setProperty("global.road", roads, true);
        
        // Resize village labels
        let village = style.getProperty("global.place_village_font_size");
        village = changeLabel(village, 2.5);
        style.setProperty("global.place_village_font_size", village, true);

        let roadStyle = new H.map.Style(style.extractConfig(['road_labels', 'roads.shields']));
        this.roads = this.platform.getOMVService().createLayer(roadStyle);
        this.map.addLayer(this.roads, 8);
        
        this.truckRestrictions = new H.map.Style(style.extractConfig(['truck-restriction']));
        this.showRouteRetrictions();
      }
    }
    
    style.addEventListener('change', changeListener);
  }

  componentDidMount() {
    const { onlyTransactions } = this.props;
    const platform = new H.service.Platform({
      apikey: this.state.app_apikey
    });

    const defaultLayers = platform.createDefaultLayers({opt_lang: 'fi'});

    let baseLayer = defaultLayers.vector.normal.truck;
    const container = document.getElementById("here-map");
    const map =  
      new H.Map(container, baseLayer, {
        center: this.center,
        zoom: this.zoom,
        autoColor: false,
        pixelRatio: 2
      });

    map.addObject(this.accuracyCircle);

    {
      // Routeline layer
      let objectProvider = new H.map.provider.LocalObjectProvider();
      this.routeLineLayer = new H.map.layer.ObjectLayer(objectProvider);
      this.routeLines = objectProvider.getRootGroup();
      map.addLayer(this.routeLineLayer, 1);
    }

    {
      // Road Hazard Marker layer
      let objectProvider = new H.map.provider.LocalObjectProvider();
      this.roadHazardsLayer = new H.map.layer.ObjectLayer(objectProvider);
      this.roadHazardsGroup = objectProvider.getRootGroup();
      map.addLayer(this.roadHazardsLayer, 2);
    }

    {
      // User added Waypoint layer
      let objectProvider = new H.map.provider.LocalObjectProvider();
      this.waypointMarkerLayer = new H.map.layer.ObjectLayer(objectProvider);
      this.waypointMarkerGroup = objectProvider.getRootGroup();
      map.addLayer(this.waypointMarkerLayer, 3);
    }

    {
      // Position marker layer
      let objectProvider = new H.map.provider.LocalObjectProvider();
      this.positionMarkerLayer = new H.map.layer.ObjectLayer(objectProvider);
      this.positionMarkerGroup = objectProvider.getRootGroup();
      map.addLayer(this.positionMarkerLayer, 4);
      this.showPositionMarker();
    }

    {
      // Address layer
      let objectProvider = new H.map.provider.LocalObjectProvider();
      this.addressMarkerLayer = new H.map.layer.ObjectLayer(objectProvider);
      this.addressMarkerGroup = objectProvider.getRootGroup();
      map.addLayer(this.addressMarkerLayer, 5);
    }

    {
      // Route Objects layer
      let objectProvider = new H.map.provider.LocalObjectProvider();
      this.routeObjectsLayer = new H.map.layer.ObjectLayer(objectProvider);
      this.routeObjects = objectProvider.getRootGroup();
      map.addLayer(this.routeObjectsLayer, 6);
    }

    {
      // Truck Marker layer
      let objectProvider = new H.map.provider.LocalObjectProvider();
      this.truckLayer = new H.map.layer.ObjectLayer(objectProvider);
      if (!onlyTransactions) { objectProvider.getRootGroup().addObject(this.truck.getMarker()); }
      map.addLayer(this.truckLayer, 100);
    }

    this.map = map;

    // Enable pointer interactions with map
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    // Remove map settings from the UI
    const ui = new H.ui.UI.createDefault(map, defaultLayers, 'fi-FI');
    ui.removeControl('mapsettings');

    /**
     * Attach event listener to zoom controls
     * On user action check if application needs to leave approach mode and let the user zoom again
     */
    const z = ui.getControl('zoom');
    z.getElement().addEventListener('mousedown', () => this.leaveApproachMode());
    z.getElement().addEventListener('touchstart', () => this.leaveApproachMode());

    if (this.state.mapMode === 'light') {
      this.setBaseLayerStyle(this.map);
    }

    this.platform = platform;
    this.defaultLayers = defaultLayers;
    geoCache.setGeocoder(platform.getGeocodingService());

    // Add click event to markers
    this.routeObjects.addEventListener('tap', function (evt) {
      // event target is the marker itself, group is a parent event target
      // for all objects that it contains
      var bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
        // read custom data
        content: evt.target.getData()
      });

      // Remove old bubbles
      const bubbles = ui.getBubbles();
      bubbles.forEach(item => ui.removeBubble(item));

      // show info bubble
      ui.addBubble(bubble);
    }, false);

    // Originally this was to turn on HereMaps traffic incidents as default
    // However, this API causes a large cost for VR Transpoint so disable for 
    // the minute until Digitraffic APIs are integrated.
    //!this.state.showTrafficIncidents && this.toggleTrafficIncidents();

    window.addEventListener("resize", this.onWindowResize);
    this.map.addEventListener("dragstart", this.onMapDragStart);
    this.map.addEventListener("mapviewchange", this.onMapViewChange);
    this.map.addEventListener("mapviewchangeend", this.onMapViewChangeEnd);
    this.map.addEventListener("longpress", this.onMapLongPress);

    if (this.map) {
      this.initMap();
    }

    this.props.geolocation && this.props.geolocation.subscribe(this.onGeolocationUpdate);

    this.mapReady = false;
    let terrainMode = retrieveLocalData("terrainMode");
    if (!isEmpty(terrainMode) && terrainMode.mode === 'satellite') {
      this.onMapReady(terrainMode);
    }

    this.mounted = true;
  }

  onMapReady(terrainMode) {
    if(!this.mapReady && this.roads === undefined) {
      setTimeout(() => this.onMapReady(terrainMode), 500);
    } else {
      this.toggleDayNightMode(this.state.mapMode, terrainMode.mode);
      this.mapReady = true;
    }
  }

  /**
   * componentDidUpdate
   * Refresh styles (day/nightmode)
   * Initialize map if destinations have changed
   * Refresh road hazards if a change is detected
   * Trigger a window resize event
   * @param {object} prevProps 
   */
  componentDidUpdate(prevProps) {
    this.props.refreshStyles();

    if (!deepEqual(prevProps.destinations, this.props.destinations)) {
      this.initMap();
    }

    if (!deepEqual(this.props.roadHazards, prevProps.roadHazards)) {
      this.refreshRoadHazards();
    }

    this.onWindowResize(null);
  }

  /**
   * onGeolocationUpdate
   * Triggered by a subscription to navigator.geolocation.watchPosition in Geolocated.js
   * Update center and truck position
   * Update accuracy circle
   * Follow (center to route/truck) if set
   * @param {*} pos 
   */
  onGeolocationUpdate(pos) {
    if (this.mounted) {
      const center = new Coords(pos.latitude, pos.longitude);
      this.center = center;
      this.setStartLocation(center);
      this.updateTruckPosition(center, false);
      this.updateAccuracyCircle(center, pos.accuracy);
      this.follow();

    }
  }

  approachZoomLevel() {

    let approachZoom;

    const currentWaypoint = this.getCurrentWaypoint();
    const truckLocation = this.truck.getCoords();
    const waypointLocation = currentWaypoint.getCoords();
    const distance = calcDistance(truckLocation.lng, truckLocation.lat, waypointLocation.lng, waypointLocation.lat)
    
    if(distance <= 2){
      approachZoom = 16.6;
    }
    if(distance > 2 && distance <= 5){
      approachZoom = 16;
    }
    if(distance > 5 && distance <= 10){
      approachZoom = 13.8;
    }
    if(distance > 10 && distance <= 20){
      approachZoom = 11;
    }
    if(distance > 20){
      approachZoom = 10.6;
    }

    return approachZoom;

  }

  /**
   * follow
   * Center to truck or route depending on current state.follow setting
   */
  follow() {
    switch (this.state.follow) {
      case HEREMap.followTypes.truck:
        this.centerToMarker(this.truck.getMarker(), this.map.getZoom());
        break;
      case HEREMap.followTypes.approach:
        const approachZoomLevel = this.approachZoomLevel();
        this.map.setZoom(approachZoomLevel);
        this.zoom = this.map.getZoom();
        this.centerToMarker(this.truck.getMarker(), approachZoomLevel);
        break;
      case HEREMap.followTypes.currentRoute:
        this.centerToWaypoints();
        break;
      default:
        break;
    }
  }

  showPositionMarker() {
    let location = retrieveLocalData("positionMarkerLocation");
    if (!isEmpty(location)) {
      this.addPositionMarkerToMap(location);
      this.setState({positionMarkerInMap: true});
    } else {
      this.setState({positionMarkerInMap: false});
    }
  }

  togglePositionMarker() {
    let location = retrieveLocalData("positionMarkerLocation");
    if (isEmpty(location)) {
      location = this.state.center;
      storeLocalData("positionMarkerLocation", location);
      this.addPositionMarkerToMap(location);
      this.setState({positionMarkerInMap: true});
    } else {
      storeLocalData("positionMarkerLocation", {});
      this.positionMarkerGroup.removeAll();
      this.setState({positionMarkerInMap: false});
    }
  }

  async showRoute() {
    let showRoute = retrieveLocalData("showRoute");
    if (isEmpty(showRoute) || showRoute.active) {
      this.redrawRoute();
      this.setState({routeLinesInMap: true});
    } else {
      this.drawRouteObjects();
      this.setState({routeLinesInMap: false});
    }
  }

  async toggleRoute() {
    let showRoute = retrieveLocalData("showRoute");
    if (!isEmpty(showRoute) && !showRoute.active) {
      storeLocalData("showRoute", {"active": true});
      this.redrawRoute();
      this.setState({routeLinesInMap: true});
    } else {
      storeLocalData("showRoute", {"active": false});
      this.routeLines.removeAll();
      this.setState({routeLinesInMap: false});
    }
  }
  
  showRouteRetrictions() {
    let showRouteRetrictions = retrieveLocalData("showRouteRetrictions");
    if (isEmpty(showRouteRetrictions) || showRouteRetrictions.active) {
      this.truckRestrictionsLayer = this.platform.getOMVService().createLayer(this.truckRestrictions);
      this.map.addLayer(this.truckRestrictionsLayer, 7);
      this.setState({routeRestrictionsInMap: true});
    } else {
      this.map.removeLayer(this.truckRestrictionsLayer);
      this.setState({routeRestrictionsInMap: false});
    }
  }

  toggleRouteRetrictions() {
    let showRouteRetrictions = retrieveLocalData("showRouteRetrictions");
    if (!isEmpty(showRouteRetrictions) && !showRouteRetrictions.active) {
      storeLocalData("showRouteRetrictions", {"active": true});
      this.truckRestrictionsLayer = this.platform.getOMVService().createLayer(this.truckRestrictions);
      this.map.addLayer(this.truckRestrictionsLayer, 7);
      this.setState({routeRestrictionsInMap: true});
    } else {
      storeLocalData("showRouteRetrictions", {"active": false});
      this.map.removeLayer(this.truckRestrictionsLayer);
      this.setState({routeRestrictionsInMap: false});
    }
  }

  async drawRouteObjects() {
    this.redrawRoute(true);
  }

  async redrawRoute(skipRoutes) {
    const { onlyTransactions } = this.props;
    if (this.waypoints.length > 0) {
      this.routeLines.removeAll();
      this.routeObjects.removeAll();
      const routelines = await this.calculateRouteObjects();
      if (routelines && routelines.length > 0 && !skipRoutes) {
        this.routeLines.addObjects(routelines);
      }
      // Draw current route from truck to first waypoint
      // Do not draw it if we're showing only the transactions
      if (!onlyTransactions && !skipRoutes) { this.routeLines.addObject(this.currentRoute); }
      //this.routeObjects.addObject(this.routeLines);
      // Get waypoint markers and clean out defected (null) ones
      let waypointMarkers = this.waypoints.map(item => item.getMarker()).filter(item => !!item);
      waypointMarkers.length > 0 && this.routeObjects.addObjects(waypointMarkers);
    }
  }

  addPositionMarkerToMap(location) {
    let positionMarker = new PositionMarker(
      location, {}
    );
    this.positionMarkerGroup.addObject(positionMarker.getMarker());
  }

  addWaypointMarkerToMap(location) {
    this.waypointMarkerGroup.removeAll();
    let waypointMarker = new PositionMarker(
      location, {type: PositionMarker.Type.waypoint}
    );
    this.waypointMarkerGroup.addObject(waypointMarker.getMarker());

    // Add click event to waypointMarker for deletion
    var map = this;
    this.waypointMarkerGroup.addEventListener('tap', function (evt) {
      storeLocalData("userAddedWaypointLocation", {});
      map.waypointMarkerGroup.removeAll();
      map.updateTruckPosition(map.center, true);
    });

    return waypointMarker;
  }

  addAddressMarkerToMap(location) {
    this.addressMarkerGroup.removeAll();
    if(location) {
      let addressMarker = new PositionMarker(
        location, {type: PositionMarker.Type.address}
      );
      this.addressMarkerGroup.addObject(addressMarker.getMarker());
      this.centerMapToPoint(addressMarker.getCoords(), this.map.getZoom());
    }
  }

  /**
   * centerToRoute
   * Call centerMapToBounds with route bounding box
   * @param {*} route OPTIONAL
   */
  centerToRoute(route = this.routeLines) {
    this.centerMapToBounds(route.getBoundingBox());
  }

  /**
   * centerToWaypoints
   * Call centerMapToBounds with combined waypoints bounding box
   * @param {*} waypoints OPTIONAL
   */
   centerToWaypoints(waypoints = this.waypoints) {
    let line = new H.geo.LineString();
    waypoints.forEach(function (waypoint) {
      line.pushLatLngAlt(waypoint.getCoords().lat, waypoint.getCoords().lng);
    });
    this.centerMapToBounds(line.getBoundingBox());
  }

  /**
   * centerToMarker
   * Call centerMapToPoint with truck location and zoom value
   * @param {*} marker 
   * @param {int} zoom 
   */
  centerToMarker(marker = this.truck, zoom) {
    this.centerMapToPoint(marker.getGeometry(), zoom);
  }

  /**
   * centerMapToBounds
   * Show map with padding based on given bounds
   * @param {*} bounds 
   */
  centerMapToBounds(bounds) {
    const { onlyTransactions } = this.props;
    if (bounds) {
      const vpadding = onlyTransactions ? bounds.getHeight() * 0.3 : bounds.getHeight() * 0.1;
      const hpadding = onlyTransactions ? bounds.getWidth() * 0.3 : bounds.getWidth() * 0.1;
      const paddedBounds = new H.geo.Rect(
        bounds.getTop() + vpadding,
        bounds.getLeft() - hpadding,
        bounds.getBottom() - vpadding,
        bounds.getRight() + hpadding
      );
      this.autoCentering = true;
      this.map.getViewModel().setLookAtData(
        {
          bounds: paddedBounds
        },
        true
      );
    }
  }

  /**
   * centerMapToPoint
   * Take given point & zoom amount and center map to this point
   * @param {*} point 
   * @param {int} zoom 
   */
  centerMapToPoint(point, zoom) {
    this.autoCentering = true;
    this.map.getViewModel().setLookAtData(
      {
        position: point,
        zoom: isNaN(zoom) ? 16 : zoom,
      },
      false
    )
  }

  /**
   * ToggleTerrainMode helper function
   * Changes terrain mode in the internal state and fires mode toggling after that
   */
  toggleTerrainMode() {
    const newTerrain = this.state.terrainMode === 'normal' ? 'satellite' : 'normal';
    storeLocalData("terrainMode", {"mode": newTerrain});
    this.setState({terrainMode: newTerrain});
    this.toggleDayNightMode(this.state.mapMode, newTerrain);
  }

  /**
   * toggleDayNightMode
   * Toggle map layers between "normal" and "night"
   * Triggered by user interaction by pressing the night/day button
   * @param {string} newMode 
   */
  toggleDayNightMode(newMode, newTerrain) {

    const terrain = newTerrain || this.state.terrainMode;

    const mode = `${newMode}${terrain}`;
    this.setState({ mapMode: newMode });
    let roadStyle = this.roads.getProvider().getStyle();

    if (mode === 'darksatellite') {
      this.map.addLayer(this.defaultLayers.raster.satellite.base, 0);
      this.map.removeLayer(this.defaultLayers.vector.normal.truck);
      this.map.removeLayer(this.defaultLayers.raster.normal.basenight);
      roadStyle.setProperty("global.text_fill", '#CCCCCC', true);
      roadStyle.setProperty("global.text_outline", '#000000', true);
    } else if (mode === 'lightsatellite') {
      this.map.addLayer(this.defaultLayers.raster.satellite.base, 0);
      this.map.removeLayer(this.defaultLayers.vector.normal.truck);
      this.map.removeLayer(this.defaultLayers.raster.normal.basenight);
      roadStyle.setProperty("global.text_fill", '#FFFFFF', true);
      roadStyle.setProperty("global.text_outline", '#000000', true);
    } else if (mode === 'darknormal') {
      this.map.addLayer(this.defaultLayers.raster.normal.basenight, 0);
      this.map.removeLayer(this.defaultLayers.vector.normal.truck);
      this.map.removeLayer(this.defaultLayers.raster.satellite.base);
      roadStyle.setProperty("global.text_fill", '#FFFFFF', true);
      roadStyle.setProperty("global.text_outline", '#000000', true);
    } else {
      this.map.addLayer(this.defaultLayers.vector.normal.truck, 0);
      this.map.removeLayer(this.defaultLayers.raster.normal.basenight);
      this.map.removeLayer(this.defaultLayers.raster.satellite.base);
      this.setBaseLayerStyle(this.map);
      roadStyle.setProperty("global.text_fill", '#000000', true);
      roadStyle.setProperty("global.text_outline", '#FFFFFF', true);
    }
  }

  /**
   * initMap
   * Overwrite old waypoints with new ones based on upcoming destination data (asynchronous call)
   * Mark current waypoint
   * Redraw map
   */
  async initMap() {
    let waypoints;
    try {
      waypoints = await this.geocodeWaypoints();
    } catch (e) {
      // use existing waypoints if geocoding fails
      waypoints = this.waypoints;
    }
    this.waypoints = [];
    this.addWaypoints(waypoints); // Add destination waypoints
    this.markCurrentWaypoint(this.props.currentDestination);
    this.redrawMap();
  }

  /**
   * refreshRoadHazards
   * Adds road hazard pins to map
   */
  refreshRoadHazards() {
    this.roadHazardsGroup.removeAll();
    this.props.roadHazards && this.props.roadHazards.forEach(roadHazard => {
      let type = null;
      switch (roadHazard.type) {
        case roadHazards.types.accident:
          type = roadHazardPin.Type.accident;
          break;
        case roadHazards.types.slippery:
          type = roadHazardPin.Type.slippery;
          break;
        case roadHazards.types.reindeers:
          type = roadHazardPin.Type.reindeer;
          break;
        default:
          type = roadHazardPin.Type.other;
          break;
      }
      let pin = new roadHazardPin(
        new Coords(roadHazard.latitude, roadHazard.longitude),
        { type }
      );
      this.roadHazardsGroup.addObject(pin.getMarker());
    });
  }

  /**
   * markCurrentWaypoint
   * @param {object} currentDestination 
   * Set currentDestination as currentWaypoint
   * Refresh marker layout with currentWaypoint.setStatus
   * Show dialog if waypoint is not found on map
   */
  markCurrentWaypoint(currentDestination) {
    if (currentDestination) {
      const currentWaypoint = this.getWaypointById(currentDestination.id);
      if (currentWaypoint && currentWaypoint.status !== Waypoint.Status.current) {
        currentWaypoint.setStatus(Waypoint.Status.current);
        this.setState({
          currentWaypointAccuracy: currentWaypoint.accuracy,
          showWaypointAccuracyPopup: currentWaypoint.accuracy !== Waypoint.Accuracy.address
        });
      }
    }
    else {
      this.setState({ currentWaypointAccuracy: null, showWaypointAccuracyPopup: false });
    }
  }

  /**
   * getWaypointById
   * @param {*} id
   * Returns a waypoint from this.waypoints list on ID match
   */
  getWaypointById(id) {
    return id && this.waypoints.find((waypoint => `${waypoint.id}` === `${id}`))
  }

  /**
   * getDestinationInfo
   * @param {object} waypoint 
   * Returns a destination from this.props.destinations on ID match
   */
  getDestinationInfo(waypoint) {
    return waypoint && waypoint.id && this.props.destinations.find(({ id }) => `${id}` === `${waypoint.id}`);
  }

  /**
   * redrawMap
   * Remove map content & routes
   * Update truck position to map center
   * Redraw route lines
   * Refresh road hazards
   * Resize map viewport
   * 
   * If map is not currently ready, center to truck after 500ms
   */
  async redrawMap() {
    const { onlyTransactions } = this.props;
    this.map.clearContent();
    this.updateTruckPosition(this.center, true);
    this.showRoute();
    this.refreshRoadHazards();
    this.map.getViewPort().resize();

    if (!this.state.isReady) {
      if (onlyTransactions) {
        setTimeout(() => this.setFollow(HEREMap.followTypes.currentRoute, true), 500);
      } else {
        setTimeout(() => this.setFollow(this.state.follow, true), 500);
      }
      this.setState({ isReady: true });
    }

    if (onlyTransactions) { this.setFollow(HEREMap.followTypes.currentRoute, true); }

  }

  /**
   * geocodeWaypoints
   * Get waypoint coordinates based on destinations address information
   * Use cached coordinates data if available
   */
  geocodeWaypoints = (() => {
    const { onlyTransactions } = this.props;
    let requestId = 0;
    return async () => {
      const thisId = ++requestId;
      // TMP Filter by status
      const upcomingDests = this.props.destinations.filter(dest => {
        const destDate = moment(dest.eta, "DD-MM-YYYY HH-mm");
        return destDate > moment().subtract(12, "hours")
          || (
            destDate < moment().add(24, "hours")
            && !["completed", "cancelled"].includes(dest.status)
          );
      });
      const destinations = getDestinationsByDates(upcomingDests);

      return new Promise((resolve, reject) => {
        Promise.all(
          destinations.map(destination => {
            return new Promise((resolve) => {
              let type =
                destination.type === "load"
                  ? Waypoint.Type.load
                  : Waypoint.Type.unload;
              let status;
              switch (destination.state) {
                case "cancelled":
                  status = Waypoint.Status.cancelled;
                  break;
                case "completed":
                  status = Waypoint.Status.done;
                  break;
                default:
                  status = Waypoint.Status.default;
                  break;
              }

              const destinationInformation = {
                type: destination.type === "load" ? "Lastaus" : "Purku",
                name: destination.name,
                address: (get(destination, "address", "") || "").trim(),
                zipcode: destination.zipcode,
                city: (get(destination, "city", "") || "")
                  .trim()
                  .toLowerCase()
                  .replace(/^\w/, c => c.toUpperCase()),
                addressAdditionalInfo: destination.addressAdditionalInfo && destination.addressAdditionalInfo,
                contactPerson: destination.contactPerson ? destination.contactPerson : '',
                contactPhone: destination.contactPhone ? destination.contactPhone : '',
                transactions: destination.transactions,
                onlyTransactions: onlyTransactions
              };

              if (destination.coordinatesX && destination.coordinatesY) {
                // Use existing coordinates
                resolve(new Waypoint(destination.id, new Coords(destination.coordinatesY, destination.coordinatesX), type, status, Waypoint.Accuracy.address, destination.order, destinationInformation));

              }
              else {
                // Try to geocode coordinates from the address
                geoCache
                  .get(destination.address, destination.zipcode, destination.city)
                  .then(result => {
                    // Geocoding successful
                    resolve(new Waypoint(destination.id, result, type, status, Waypoint.Accuracy.address, destination.order, destinationInformation));
                  })
                  .catch(() => {
                    // Try to geocode just the zipcode if full address fails
                    geoCache
                      .get(null, destination.zipcode, destination.city)
                      .then(result => {
                        // Was able to geocode the zipcode but not full address
                        resolve(new Waypoint(destination.id, result, type, status, Waypoint.Accuracy.area, destination.order, destinationInformation));
                      })
                      .catch(() => {
                        // Failed geocoding
                        resolve(new Waypoint(destination.id, null, type, status, Waypoint.Accuracy.none, destination.order, destinationInformation));
                      });
                  });
              }
            });
          })
        ).then(results => {
          if (thisId === requestId) {
            resolve(results);
          }
          else {
            reject("outdated data");
          }
        });
      });
    }
  })();

  /**
   * addWaypoints
   * Concatenate current and given waypoints together
   * @param {array} waypoints 
   */
  addWaypoints(waypoints) {
    this.waypoints = this.waypoints.concat(waypoints);
  }

  /**
   * UpdateTruckPosition
   * Set truck position on map (unbillable)
   * Calculate route between truck position and next waypoint (billable)
   * @param {object} coords                     Latitude and longitude
   * @param {booleanObjectType} allowRouting    Allow routing conditional - if false then a new Here Maps route will not be calculated
   */
  async updateTruckPosition(coords, allowRouting) {
    this.truck.setPosition(coords);
    const currentWaypoint = this.getCurrentWaypoint();
    if (currentWaypoint && allowRouting && currentWaypoint.accuracy !== Waypoint.Accuracy.none) {
      const currentUserAddedWaypoint = this.getCurrentUserAddedWaypoint();
      let routeGeometry = new H.geo.MultiLineString([]);
      if(currentUserAddedWaypoint) {
        this.getRouteBetweenAandB(
          this.truck.getCoords(),
          currentUserAddedWaypoint.getCoords()
        ).then(route => {
            routeGeometry.push(route.getGeometry());
            this.getRouteBetweenAandB(
              currentUserAddedWaypoint.getCoords(),
              currentWaypoint.getCoords()
            ).then(route => {
                routeGeometry.push(route.getGeometry());
                this.currentRoute.setGeometry(routeGeometry);
              });
          });
      } else {
        this.getRouteBetweenAandB(
          this.truck.getCoords(),
          currentWaypoint.getCoords()
        ).then(route => {
            routeGeometry.push(route.getGeometry());
            this.currentRoute.setGeometry(routeGeometry);
          });
      }
    }
  }

  /**
   * updateAccuracyCircle
   * @param {*} coords 
   * @param {*} accuracy 
   */
  updateAccuracyCircle(coords, accuracy) {
    if (!isNaN(coords.lat)) {
      this.accuracyCircle.setCenter(coords);
    }
    if (!isNaN(accuracy)) {
      this.accuracyCircle.setRadius(accuracy);
      this.accuracyCircle.setVisibility(accuracy > 10);
    }
  }

  /**
   * getCurrentWaypoint
   * Return waypoint which matches status criteria for current waypoint
   */
  getCurrentWaypoint() {
    return this.waypoints.find(waypoint => {
      return waypoint.status !== Waypoint.Status.done &&
        waypoint.status !== Waypoint.Status.cancelled &&
        waypoint.type !== Waypoint.Type.start;
    });
  }

  /**
   * getCurrentUserAddedWaypoint
   * Return waypoint that user has added to the map
   */
  getCurrentUserAddedWaypoint() {
    this.userAddedWaypoint = undefined;
    let location = retrieveLocalData("userAddedWaypointLocation");
    if (!isEmpty(location)) {
      this.userAddedWaypoint = this.addWaypointMarkerToMap(location);
    }
    return this.userAddedWaypoint;
  }

  /**
   * getPrevWaypoint
   * Return waypoint which holds the previous index in waypoints array
   */
  getPrevWaypoint() {
    let i = this.waypoints.indexOf(this.getCurrentWaypoint());
    while (--i > 0) {
      if (this.waypoints[i].status !== Waypoint.Status.cancelled &&
        this.waypoints[i].accuracy !== Waypoint.Accuracy.none) {
        return this.waypoints[i];
      }
    }
    if (this.waypoints[0].accuracy !== Waypoint.Accuracy.none) {
      return this.waypoints[0];
    }
    return null;
  }

  /**
   * setStartLocation
   * @param {*} coords 
   * Initialize map if not initialized yet.
   */
  setStartLocation(coords) {
    if (!this.startInitialized) {
      this.initMap();
      this.startInitialized = true;
    }
  }

  /**
   * setFollow
   * @param {*} type 
   * @param {*} force
   * Center map to truck, route or disable the functionality
   */
  setFollow(type, force) {
    if (Object.values(HEREMap.followTypes).includes(type)) {
      if (!force && this.state.follow === type) {
        storeLocalData("followType", {"type": HEREMap.followTypes.none});
        this.setState({ follow: HEREMap.followTypes.none });
      }
      else {
        storeLocalData("followType", {"type": type});
        this.setState({ follow: type });

        // Force currentroute follow if showing only transactions
        const followType = this.props.onlyTransactions ? 2 : type;

        switch (followType) {
          case HEREMap.followTypes.truck:
            this.centerToMarker(this.truck.getMarker(), 16);
            break;
          case HEREMap.followTypes.approach:
            const approachZoomLevel = this.approachZoomLevel();
            this.map.setZoom(approachZoomLevel);
            this.zoom = this.map.getZoom();
            this.centerToMarker(this.truck.getMarker(), approachZoomLevel);
            break;
          case HEREMap.followTypes.currentRoute:
            this.centerToWaypoints();
            break;
          default:
            break;
        }
      }
    }
  }

  //https://developer.here.com/api-explorer/maps-js/servicesRouting/map-with-route-from-a-to-b
  // Calculate polyline routes between waypoints

  /**
   * calculateRouteObjects
   * Calculate polyline routes between waypoints
   * Create promises for each part of the route and start getting the route between the given coordinate points
   */
  async calculateRouteObjects() {
    let currentIndex = 0;
    if (this.waypoints.length <= 1 || currentIndex < 0) {
      // No need to draw the route as there are no waypoints
      return;
    }
    let promisses = [];
    let waypointA = this.waypoints[currentIndex++];
    if (!waypointA) {
      return null;
    }
    while (currentIndex < this.waypoints.length) {
      let waypointB = this.waypoints[currentIndex++];
      if (![Waypoint.Status.cancelled].includes(waypointB.status) && waypointB.accuracy !== Waypoint.Accuracy.none) {
        promisses.push(
          this.getRouteBetweenAandB(
            waypointA.getCoords(),
            waypointB.getCoords(),
            `rgba(128, 128, 128, 1)`
          )
        );
        waypointA = waypointB;
      }
    }
    return Promise.all(promisses);
  }

  /**
   * getRouteBetweenAandB
   * @param {*} a 
   * @param {*} b 
   * @param {*} color
   * Look for a cached route.
   * If no cached route found calculate new route and return it as a promise
   */
  async getRouteBetweenAandB(a, b, color = "grey") {
    return routeCache.get(
      `${a.lat},${a.lng}-${b.lat},${b.lng}`,
      () => {
        return new Promise((resolve, reject) => {
          let routingParameters = {
            // The routing mode: https://developer.here.com/documentation/routing/topics/resource-param-type-routing-mode.html
            mode: "fastest;truck",
            limitedWeight: 76,
            height: 4.4,
            width: 2.6,
            // The start point of the route:
            waypoint0: `geo!${a.lat},${a.lng}`,
            // The end point of the route:
            waypoint1: `geo!${b.lat},${b.lng}`,
            // To retrieve the shape of the route we choose the route
            // representation mode 'display'
            representation: "display"
          };

          const router = this.platform.getRoutingService();
          router.calculateRoute(
            routingParameters,
            result => {
              let route,
                routeShape,
                // startPoint,
                // endPoint,
                linestring;
              if (result.response && result.response.route) {
                // Pick the first route from the response:
                route = result.response.route[0];
                // Pick the route's shape:
                routeShape = route.shape;
                // Create a linestring to use as a point source for the route line
                linestring = new H.geo.LineString();

                // Push all the points in the shape into the linestring:
                routeShape.forEach(function (point) {
                  let parts = point.split(",");
                  linestring.pushLatLngAlt(parts[0], parts[1]);
                });

                // Retrieve the mapped positions of the requested waypoints:
                // startPoint = route.waypoint[0].mappedPosition;
                // endPoint = route.waypoint[1].mappedPosition;

                // Create a polyline to display the route:
                resolve(
                  new H.map.Polyline(linestring, {
                    style: { strokeColor: color, lineWidth: 4 }
                  })
                );
              }
            },
            err => {
              console.error(err);
              reject(err);
            }
          );
        });
      }
    );
  }

  handleMapMenuClick = event => {
    this.setState({ mapMenuAnchorEl: event.currentTarget });
  }

  handleMapMenuClose = () => {
    this.setState({ mapMenuAnchorEl: null });
  }

  

  render() {
    const { onlyTransactions, destinationIdFragment } = this.props;
    const { mapMenuAnchorEl } = this.state;
    const currentWaypoint = this.getCurrentWaypoint();
    const currentDestination = this.getDestinationInfo(currentWaypoint);
    const currentDestinationStatus = get(currentDestination, ["status"], undefined);
    let navigateBackUrl = '/';
    if (currentDestinationStatus && destinationIdFragment) {
      navigateBackUrl = `/routes/schedule/${currentDestinationStatus}/destination/${destinationIdFragment}`;
    }
    //https://developer.here.com/documentation/maps/topics/routing.html

    return (
      <React.Fragment>
        
        <div id="here-map" className={styles.map} style={!this.state.isReady ? { display: "none" } : {}}>
          {!onlyTransactions &&
            <div className={styles.topLeft}>
              <AppBar position="static" color="default">
                <Toolbar>
                  <IconButton
                    aria-label="map-menu-label"
                    aria-controls="map-menu-controls"
                    aria-haspopup="true"
                    onClick={this.handleMapMenuClick}
                  >
                    <MenuIcon fontSize="large" />
                  </IconButton>
                  <Menu
                    id="map-menu"
                    anchorEl={mapMenuAnchorEl}
                    keepMounted
                    open={Boolean(mapMenuAnchorEl)}
                    onClose={this.handleMapMenuClose}
                  >
                    
                    <ListItem>Kartan tila</ListItem>
                    <MenuItem><Chip clickable onClick={() => this.setFollow(HEREMap.followTypes.none)} color={this.state.follow === HEREMap.followTypes.none ? 'primary' : 'default'} label="Ei seurantaa" /></MenuItem>
                    <MenuItem><Chip clickable onClick={() => this.setFollow(HEREMap.followTypes.truck)} color={this.state.follow === HEREMap.followTypes.truck ? 'primary' : 'default'} label="Seuraa ajoneuvoa" /></MenuItem>
                    <MenuItem><Chip clickable onClick={() => this.setFollow(HEREMap.followTypes.currentRoute)} color={this.state.follow === HEREMap.followTypes.currentRoute ? 'primary' : 'default'} label="Näytä ajoreitti" /></MenuItem>
                    <MenuItem><Chip clickable onClick={() => this.setFollow(HEREMap.followTypes.approach)} color={this.state.follow === HEREMap.followTypes.approach ? 'primary' : 'default'} label="Automaattinen lähestyminen" /></MenuItem>
                    <Divider variant="middle" style={{marginTop: '15px', marginBottom: '10px'}} />
                    
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!this.state.routeLinesInMap}
                            onChange={() => this.toggleRoute()}
                            name="routeToggle"
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                        label="Reittiviiva"
                      />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!this.state.routeRestrictionsInMap}
                            onChange={() => this.toggleRouteRetrictions()}
                            name="routeToggleRetrictions"
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                        label="Rajoitusmerkit"
                      />
                    </FormGroup>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.terrainMode === 'satellite'}
                            onChange={() => this.toggleTerrainMode()}
                            name="toggleTerrainMode"
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                        label="Satelliittinäkymä"
                      />
                    </FormGroup>
                    <Divider variant="middle" style={{marginBottom: '10px', marginTop: '15px'}} />
                    <MenuItem onClick={() => this.redrawMap()}>Päivitä ajoreitti</MenuItem>
                    <MenuItem onClick={() => this.togglePositionMarker()}>{!!this.state.positionMarkerInMap ? "Poista karttamerkki" : "Tallenna karttamerkki"}</MenuItem>
                  </Menu>
                  <AddressSearch onSelect={(location) => this.addAddressMarkerToMap(location)}/>
                </Toolbar>
              </AppBar>
            </div>
          }
          {!onlyTransactions &&
            <div className={styles.bottomLeft}>
              <LogRoadHazardButton onClick={() => navigate("map/road-hazard")} disabled={false}>
                Log Road Hazard
              </LogRoadHazardButton>
            </div>
          }
          {onlyTransactions &&
            <div className={styles.wrapper}>
              <header>
                <Link to={navigateBackUrl}>Takaisin</Link>
              </header>
            </div>
          }
        </div>
        {this.state.showWaypointAccuracyPopup &&
          currentDestination &&
          this.state.currentWaypointAccuracy !== null &&
          this.state.currentWaypointAccuracy !== Waypoint.Accuracy.address && (
            // Show a modal when the geolocation of the current waypoint
            // could not be geocoded from the address.
            <Modal uncloseable={true} contained={true} zIndex={100}>
              <React.Fragment>
                {this.state.currentWaypointAccuracy === Waypoint.Accuracy.area ? (
                  <h3>Seuraavan kohteen tarkkaa sijaintia ei löytynyt kartalta:</h3>
                ) : (
                    <h3>Seuraavaa kohdetta ei löytynyt kartalta:</h3>
                  )}
                <p><strong>{currentDestination.name}</strong></p>
                <p>{currentDestination.address}</p>
                <p>{currentDestination.zipcode}, {currentDestination.city}</p>
                <p>
                  <Button onClick={() => this.setState({ showWaypointAccuracyPopup: false })}>Sulje</Button>
                </p>
              </React.Fragment>
            </Modal>
          )}
        {!this.state.isReady && (
          <Loading>
            Ladataan karttaa…
          </Loading>
        )}
      </React.Fragment>
    );
  }
}

HEREMap.propTypes = {
  app_apikey: PropTypes.string.isRequired,
  geolocated: PropTypes.bool.isRequired,
  geolocation: PropTypes.shape({
    restart: PropTypes.func.isRequired,
    geolocated: PropTypes.bool.isRequired,
    getLocation: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
    unsubscribe: PropTypes.func.isRequired,
  }),
  destinations: PropTypes.array.isRequired,
  currentDestination: PropTypes.any,
};

export default HEREMap;
