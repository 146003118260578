import gql from "graphql-tag";

export const adminRemoveUserFromReportsMutation = gql`
  mutation AdminRemoveUserFromReports($userid: String!) {
    adminRemoveUserFromReports(userid: $userid) {
      id
      reportName
      reportId
      contentId
      contractorIds
    }
  }
`;
