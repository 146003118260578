import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Field from "./GreenField";
import Button from "./Button";

import styles from "./LoadingDetailsInput.module.scss";
import amountValidation from "../../containers/data/utils/amountValidation";
import { ApolloConsumer } from "react-apollo";
import { getWaybillNumber } from "../../api/graphql/getWaybillNumber";
import Spinner from "../layout/Spinner";

class LoadingDetailsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    // store the initial values as user input to enable validation
    const { waybillNum, actualAmount, weightNoteNumberLoading,
      container1Load, container2Load, container3Load, container4Load } = this.props;
    this.props.onChange({
      waybill: waybillNum,
      amountLoaded: actualAmount,
      weightNoteNumberLoading: weightNoteNumberLoading,
      container1Load: container1Load,
      container2Load: container2Load,
      container3Load: container3Load,
      container4Load: container4Load
    });
  }

  validate(values) {
    // This function call updates the state of the parent component
    // so the form input is stored correctly.
    this.props.onChange(values);
    const errors = {};
    if (!this.validateWaybill(values.waybill)) {
      errors.waybill = 'Pakollinen. Sallitut merkit ovat A-Z kirjaimet, numerot, väliviiva, kauttaviiva sekä alaviiva';
    }

    const amountError = this.validateAmount(values.amountLoaded);
    if (amountError) {
      errors.amountLoaded = amountError;
    }

    return errors;
  }

  validateWaybill(value) {
    if (!value || value.trim() === "") {
      return false;
    }

    const match = value.match(/([A-Za-z0-9\-\_\/]+)$/gi);
    if (match === null || match[0] !== value) {
      return false;
    }
    return value && value.toString().length > 0;
  }

  validateAmount(value) {
    const {loadUnit} = this.props;
    return amountValidation(value, loadUnit);
  }

  fetchWaybillNumber(apolloClient, orderNum, customerNum, formik) {
    this.setState({loading: true});

    apolloClient.query({
      query: getWaybillNumber,
      variables: { customerNumber: customerNum, orderNumber: orderNum },
      fetchPolicy: "cache-first"
    }).then(({ data, error }) => {
      if (!error && data) {
        if (data.getWaybillNumber) {
          formik.setFieldValue("waybill", data.getWaybillNumber ?? "");
          setTimeout(() => formik.setFieldTouched("waybill", true));
          this.props.onCopyWaybill(data.getWaybillNumber ?? "");
        } else {
          console.log(`Falsy waybill number returned: ${data.getWaybillNumber}`);
        }
      } else if (error) {
        console.log("getWaybillNumber returned error message", error);
      }
      this.setState({loading: false});

      this.props.onChange({
        waybill: data.getWaybillNumber
      });
    }).catch(error => {
      this.setState({loading: false});

      console.log('getWaybillNumber error', error);
    });
  }

  render() {
    const {
      loadUnit,
      waybillNum,
      weightNoteNumberLoading,
      container1Load,
      container2Load,
      container3Load,
      container4Load,
      orderNum,
      customerNum,
      fullySigned
    } = this.props;

    const actualAmount = !isNaN(this.props.actualAmount) ? this.props.actualAmount : "";
    return (
      <Formik
        enableReinitialize
        validateOnMount={true}
        initialValues={{
          waybill: waybillNum || "",
          amountLoaded: !isNaN(actualAmount) ? actualAmount : "",
          weightNoteNumberLoading: weightNoteNumberLoading || "",
          container1Load: container1Load || "",
          container2Load: container2Load || "",
          container3Load: container3Load || "",
          container4Load: container4Load || ""
        }}

        // This is hack to store the values entered in the Formik form to
        // the context of the main order - it's not actually for validating
        // the form
        validate={values => this.validate(values)}
      >
        {formik => {
          const {
            //isSubmitting,
            values,
            errors,
            touched,
            handleChange,
            //setFieldError,
          } = formik;

          this.props.onFormCreated(formik);

          const handleSettingWaybillNumber = (value) => {
            formik.setFieldValue("waybill", value);
            formik.setFieldTouched("waybill");
            this.props.onChange({ waybill: value });
          }

          return (
            <Form>
              <div className={styles.fields}>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Rahtikirja</span>
                    <div className={styles.waybillFieldContainer}>
                      <Field
                        className={styles.waybillFieldInput}
                        id="waybill"
                        name="waybill"
                        valid={!errors.waybill}
                        maxLength={20}
                        onChange={(e) => handleSettingWaybillNumber(e.target.value)}
                        disabled={fullySigned}
                      />
                      <ApolloConsumer>
                        {(apolloClient) => (
                          <Button className={styles.getButton}
                            disabled={this.state.loading || fullySigned}
                            onClick={() => this.fetchWaybillNumber(apolloClient, orderNum, customerNum, formik)}
                          >
                            {this.state.loading ? <Spinner color={"#fff"} secondaryColor={"#000"} className={styles.loadingSpinner} /> : "Hae"}
                          </Button>
                        )}
                      </ApolloConsumer>
                      <Button
                        className={styles.copyButton}
                        disabled={!!errors.waybill || fullySigned}
                        onClick={() => this.props.onCopyWaybill(values.waybill)}
                      >
                        Kopioi
                      </Button>
                    </div>
                    <span className={styles.informationText}>Aseta rahtikirjanumero ennen allekirjoittamista, rahtikirjanumeroa ei voi muuttaa allekirjoittamisen jälkeen.</span>
                  </div>
                  {errors && errors.waybill && <span className={styles.errorLabel}>{errors.waybill}</span>}
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lastattu</span>
                    <Field
                      id="amountLoaded"
                      name="amountLoaded"
                      type="text"
                      unit={loadUnit}
                      valid={!errors.amountLoaded}
                      inputMode="text"
                      pattern="[0-9,.]*"
                    />
                    {loadUnit && <span className={styles.unitLabel}>{loadUnit}</span>}
                  </div>
                  {errors && errors.amountLoaded && <span className={styles.errorLabel}>{errors.amountLoaded}</span>}
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lastauksen punnitustosite</span>
                    <Field
                      id="weightNoteNumberLoading"
                      name="weightNoteNumberLoading"
                      valid={!errors.weightNoteNumberLoading}
                      maxLength={20}
                    />
                  </div>
                  {errors && errors.weightNoteNumberLoading && <span className={styles.errorLabel}>{errors.weightNoteNumberLoading}</span>}
                </div>
              </div>

              <div className={styles.containersTitle}>Kiertotalouden kuljetukset</div>

              <div className={styles.fields}>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lavatunniste 1</span>
                    <Field id="container1Load" maxLength={20} />
                  </div>
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lavatunniste 2</span>
                    <Field id="container2Load" maxLength={20} />
                  </div>
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lavatunniste 3</span>
                    <Field id="container3Load" maxLength={20} />
                  </div>
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lavatunniste 4</span>
                    <Field id="container4Load" maxLength={20} />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    );
  }
}

LoadingDetailsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCopyWaybill: PropTypes.func.isRequired,
  onFormCreated: PropTypes.func.isRequired,
  refreshStyles: PropTypes.func.isRequired,
  loadUnit: PropTypes.string,
  waybillNum: PropTypes.string,
  weightNoteNumberLoading: PropTypes.string,
  actualAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  container1Load: PropTypes.string,
  container2Load: PropTypes.string,
  container3Load: PropTypes.string,
  container4Load: PropTypes.string,
  orderNum: PropTypes.number,
  customerNum: PropTypes.number
};

export default LoadingDetailsInput;
