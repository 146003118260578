import React, { useState } from "react";
import { navigate } from "@reach/router";
import { Query } from "react-apollo";
import { PropTypes } from "prop-types";
import Error from "../../components/layout/Error";
import Spinner from "../layout/Spinner";
import { getAccessTokenQuery } from "../../api/graphql/getAccessToken";
import { PowerBIEmbed } from "powerbi-client-react"
import { models } from "powerbi-client";
import styles from "./ViewPowerBIReport.module.scss"

const axios = require("axios");

const getReportConfig = (reportId, token, isDesktop) => {
  const layoutType = isDesktop ? models.LayoutType.Master : models.LayoutType.MobilePortrait;

  return {
    type: "report",
    id: reportId,
    embedUrl: "https://app.powerbi.com/reportEmbed",
    tokenType: models.TokenType.Embed,
    accessToken: token.token,
    viewMode: models.ViewMode.View,
    settings: {
      background: models.BackgroundType.Transparent,
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      layoutType,
      localeSettings: { language: "fi", formatLocale: "fi-FI" }
    }
  };
};

async function generateTokenForReports(reportId, extraReportIds, datasetId, username, accessToken) {
  const url = "https://api.powerbi.com/v1.0/myorg/GenerateToken";

  const identities = [{
      username,
      roles: ["Contractor"],
      datasets: [datasetId],
  }];

  const reports = [{ id: reportId }];
  if (extraReportIds) {
    extraReportIds.forEach(extraReportId => {
      if (extraReportId != 'NO_VALUE') {
        reports.push({ id: extraReportId });
      }
    });
  }
  const datasets = [{ id: datasetId, xmlaPermissions: "ReadOnly" }];
  const data = {
      accessLevel: "View",
      identities,
      reports,
      datasets,
  };

  const embedTokenResponse = await axios({
      method: "post",
      url,
      data,
      headers: { Authorization: `Bearer ${accessToken}` },
  });

  return embedTokenResponse.data;
}

const ViewPowerBIReport = ({ user, report }) => {
  const [ isLoading, setLoadingStatus ] = useState(true);
  const [ handlerError, setHandlerError ] = useState('');
  const [ reportComponent, setReportComponent ] = useState(undefined);
  const [ config, setConfig ] = useState({
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined
  });

  const { reportId, extraReportIds, contentId } = report;

  const handlers = new Map([
    ['loaded', () => { setLoadingStatus(false); }],
    ['error', (event) => {
      setHandlerError(event.detail);
      setLoadingStatus(false);
    }],
  ])

  if (handlerError) {
    return <Error error={handlerError} />;
  }

  return (
    <Query query={getAccessTokenQuery} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) {
          return <div />;
        }
        if (error) {
          return <Error error={error} />;
        }

        const { getAccessToken } = data;
        if (!getAccessToken) {
          return <div />;
        }

        if (isLoading) {
          generateTokenForReports(reportId, extraReportIds, contentId, user.contractorId, getAccessToken).then((token) => {
            setConfig(getReportConfig(reportId, token, true));
          })
          .catch((error) => {
            setHandlerError(error?.response?.data?.error ?? 'Pääsy evätty');
          })
          .finally(() => setLoadingStatus(false));
        }

        return (
          <div className={styles.root}>
            <div className={styles.veil} onClick={() => navigate("/reports")}>
              <div className={styles.modal}>
                {isLoading && <Spinner />}

                {!isLoading && reportId && config !== undefined && (
                  <PowerBIEmbed
                    embedConfig = { config }
                    eventHandlers = { handlers }
                    getEmbeddedComponent = { (embedObject) => setReportComponent(embedObject) }
                    cssClassName={ styles.reportcontainer }
                  />
                )}
              </div>
            </div>
          </div>
        )
      }}
    </Query>
  );
};

ViewPowerBIReport.propTypes = {
    user: PropTypes.shape({
        sub: PropTypes.string,
        username: PropTypes.string,
        is_enabled: PropTypes.bool,
        is_driver:  PropTypes.bool,
        is_contractor:  PropTypes.bool,
        is_admin:  PropTypes.bool,
        name: PropTypes.string,
        email: PropTypes.string,
        status: PropTypes.string,
        phone_number: PropTypes.string,
        contractor_id: PropTypes.string
        }),
    report: PropTypes.shape({
      id: PropTypes.string,
      reportName: PropTypes.string,
      reportId: PropTypes.string,
      contentId: PropTypes.string
    })
  };

export default ViewPowerBIReport;
