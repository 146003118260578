import gql from "graphql-tag";

export const getReportsQuery = gql`
  query GetReports {
    getReports {
      id
      reportName
      reportId
      contentId
      extraReportIds
    }
  }
`;

export const getReportsProps = (data) => {
  return data.getReports.map(item => ({
    id: item.id,
    reportName: item.reportName,
    reportId: item.reportId,
    contentId: item.contentId,
    extraReportIds: item.extraReportIds ?? []
  }));
};
