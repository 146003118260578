import React from "react";
import PropTypes from "prop-types";

import DeviceType from "../../enum/DeviceType";

import SignOutButton from "../SignOutButton";
import NavLink from "./NavLink";

import styles from "./Sidebar.module.scss";
import { Location } from "@reach/router";
import CssBaseline from "@material-ui/core/CssBaseline";


const Aside = ({ children }) => <aside>{children}</aside>;

const Sidebar = ({ user, vehicle, deviceType, geolocation, numOfUpcoming, changeTheme }) => {

  return (
    <Location>
      {({ location }) => {
        const sidebarHidden =
          location.pathname.startsWith("/routes/schedule/upcoming/destination") ||
          location.pathname.startsWith("/routes/schedule/completed/destination") ||
          location.pathname.startsWith("/routes/map-only-transactions");
  
        if (sidebarHidden) return null;
  
        return (
          <Aside>
            <nav className={styles.root}>
              {user.isAdmin ? (
                <React.Fragment>
                  <section>
                    <NavLink className="reports" to="reports">
                      Raportit
                    </NavLink>
                    <NavLink className="admin" to="admin">
                      {user.isAdmin ? "Käyttäjä-hallinta" : "Kuljettaja-hallinta"}
                    </NavLink>
                    <SignOutButton className="logout">
                      Kirjaudu ulos
                    </SignOutButton>
                  </section>
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <section>
                      <NavLink
                        className={`routes ${numOfUpcoming > 0 ? "upcoming" : ""}`}
                        to="routes/schedule"
                      >
                        Ajot{" "}
                        {numOfUpcoming > 0 && (
                          <span
                            style={
                              numOfUpcoming > 9 ? { letterSpacing: "-1px" } : null
                            }
                          >
                            {numOfUpcoming > 9 ? "9+" : numOfUpcoming}
                          </span>
                        )}
                      </NavLink>
                      {// Map is only available in VEHICLE mode (tracking enabled)
                        deviceType === DeviceType.VEHICLE && (
                          <React.Fragment>
                            <NavLink className="map" to="routes/map" onClick={geolocation.restart}>
                              Kartta
                            </NavLink>
                          </React.Fragment>
                        )}
                        <CssBaseline />
                        <NavLink className="day-and-night" to="routes/day-and-night" onClick={changeTheme}>
                          Päivä/Yö
                        </NavLink>
                    </section>
                    <section>
                      {vehicle && (
                        <NavLink className="vehicle" to="vehicle">
                          Auto
                          <br />
                          {vehicle.licenseNum}
                        </NavLink>
                      )}
                      {// Driver admin & user settings will be available only in the PERSONAL DEVICE -mode
                        deviceType === DeviceType.PERSONAL && (
                          <React.Fragment>
                            {user.isContractor && (
                              <React.Fragment>
                                <NavLink className="reports" to="reports">
                                  Raportit
                                </NavLink>
                                <NavLink className="admin" to="admin">
                                  Kuljettaja-hallinta
                                </NavLink>
                              </React.Fragment>
                            )}
                            <NavLink className="user" to="user">
                              Oma tili
                            </NavLink>
                          </React.Fragment>
                        )}
                      <SignOutButton className="logout">
                        Kirjaudu ulos
                      </SignOutButton>
                    </section>
                  </React.Fragment>
                )}
            </nav>
          </Aside>
        );
      }}
    </Location>
  );
}

Sidebar.propTypes = {
  user: PropTypes.shape({
    isContractor: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }).isRequired,
  deviceType: PropTypes.oneOf([DeviceType.VEHICLE, DeviceType.PERSONAL]).isRequired,
  vehicle: PropTypes.shape({
    licenseNum: PropTypes.string.isRequired
  }),
  numOfUpcoming: PropTypes.number
};

Sidebar.defaultPropTypes = {
  vehicle: null,
  numOfUpcoming: 0,
  themeType : 'light'
};

export default Sidebar;
