import React, { useState } from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";

import CurrentUser from "./CurrentUser";
import VehicleSelection from "../../components/VehicleSelection";
import RouteSelection from "../../components/RouteSelection";
import Button from "../../components/input/Button";
import CenteredContent from "../../components/layout/CenteredContent";

import { selectDeviceTypeMutation } from "../../api/graphql/selectDeviceType";
import DeviceType from "../../enum/DeviceType";
import sendMessageToNativeApp from "../../utils/sendMessageToNativeApp";

const RouteSource = ({ children, goToReports }) => {
  const [spotDriver, setSpotDriver] = useState(false);

  return (
    <CurrentUser>
      {currentUser => {
        const { routeId, vehicleId } = currentUser;

        if (!!routeId) {
          // Route selected
          return children({ routeId });
        }

        if (!!vehicleId) {
          // vehicle selected
          sendMessageToNativeApp({
            operation: 'vehicleUpdate',
            data: { vehicleId },
          });
          return children({ vehicleId });
        }

        // neither selected yet
        return spotDriver ? (
          <RouteSelection onBack={() => setSpotDriver(false)} />
        ) : (
          <>
            <VehicleSelection goToReports={goToReports} />
            <CenteredContent>
              <Mutation mutation={selectDeviceTypeMutation}>
                {selectDeviceType => (
                  <Button
                    onClick={() => {
                      selectDeviceType({
                        variables: { deviceType: DeviceType.VEHICLE },
                      });
                      setSpotDriver(true);
                    }}
                  >
                    Spot-reitti
                  </Button>
                )}
              </Mutation>
            </CenteredContent>
          </>
        );
      }}
    </CurrentUser>
  );
};

RouteSource.propTypes = {
  children: PropTypes.func.isRequired,
  goToReports: PropTypes.func.isRequired
};

export default RouteSource;
